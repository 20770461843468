<template>
  <Modal :name="$options.name"
         :is-loading="isLoading"
         title="Csatolt dokumentumok">
    <template #body
              v-if="(userAttachedDocuments && userAttachedDocuments.length > 0) ||
                    (attachedDocuments && attachedDocuments.length > 0)">
      <div v-if="userAttachedDocuments && userAttachedDocuments.length > 0">
        <h2 class="title is-5">Kérdéshez kapcsolt fájlok</h2>
        <ul>
          <li v-for="document in userAttachedDocuments"
              :key="`userAttachedDocument_${document.id}`">
            <b-icon :icon="getFileIconClass(document.ext)"/>
            <span>{{ document.name }}</span>
            <b-button type="is-primary"
                      @click="attachDocument(document.id)"
                      class="text-button-height-modifier">
              Csatolás
            </b-button>
          </li>
        </ul>
      </div>
      <div v-if="attachedDocuments && attachedDocuments.length > 0">
        <h2 class="title is-5">Összes dokumentum</h2>
        <FileTree :tree="attachedDocuments">
          <template v-if="isSurveyAttachMode"
                    v-slot:fileExtraSlot="props">
            <b-button class=" ml-2 text-button-height-modifier"
                      @click="attachDocument(props.document.id)"
                      type="is-primary">
              Csatolás
            </b-button>
          </template>
        </FileTree>
      </div>
    </template>
    <template v-else
              #body>
      Nincsenek csatolt dokumentumok
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import FileTree from '@/components/FileTree'
import { getFileIconClass } from '@/utils/utils'

export default {
  name: 'AttachedDocumentsTreeModal',
  components: {
    FileTree,
    Modal,
  },
  data () {
    return {
      isLoading: false,
    }
  },
  methods: {
    getFileIconClass,
    async attachDocument (documentID) {
      this.isLoading = true
      await this.$store.dispatch('attachFileToDocumentRegister', {
        surveyID: this.$route.params.surveyID,
        questionID: this.questionID,
        externalDocumentID: documentID,
      })
      this.isLoading = false
    },
  },
  computed: {
    userAttachedDocuments () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.userAttachedDocuments
    },
    attachedDocuments () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.attachedDocuments
    },
    questionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.questionID
    },
    isSurveyAttachMode () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.isSurveyAttachMode
    },
  },
  watch: {},
}
</script>
