<template>
  <Modal :name="$options.name"
         :is-loading="isLoading"
         title="Nyomtatás">
    <template v-if="printOptions.length > 0"
              #body>
      <b-field v-for="(item, index) in printOptions"
               :key="`inspectionPrintModalPrinter_${index}`">
        <b-checkbox v-model="printList"
                    :native-value="item.id">
          {{ item.name }}
        </b-checkbox>
      </b-field>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="print">
        Nyomtatás
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'InspectionPrintModal',
  components: {
    Modal,
  },
  data () {
    return {
      printOptions: [],
      printList: [],
      isLoading: false,
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('closeModal', this.$options.name)
      this.printList = []
      this.printOptions = []
    },
    async print () {
      // TODO: new api integration
      this.isLoading = true
      const resp = await this.$store.dispatch('printInspection', {
        inspectionID: this.inspectionID,
        printOptions: this.printList,
      })
      if (resp) {
        this.closeModal()
      }
      this.isLoading = false
    },
  },
  computed: {
    inspectionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspectionID
    },
  },
  watch: {
    async inspectionID (newValue) {
      if (newValue) {
        this.isLoading = true
        // TODO: lekér nyomtatható adatok checkbox lista
        this.printOptions = await this.$store.dispatch('fetchPrintInspectionOptions', newValue)
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
