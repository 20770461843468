<template>
  <Modal :name="$options.name"
         title="Megerősítés">
    <template #body>
      <div class="has-background-warning p-4 has-text-weight-bold">
        {{ text }}
      </div>
    </template>
    <template #footer>
      <b-button type="is-danger"
                @click="confirm">
        Igen
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'ConfirmationModal',
  components: {
    Modal,
  },
  methods: {
    closeModal () {
      this.$store.dispatch('closeModal', this.$options.name)
    },
    async confirm () {
      // noinspection JSUnresolvedVariable
      if (typeof this.payload && typeof this.payload?.confirmFunction === 'function') {
        // noinspection JSUnresolvedFunction
        this.payload.confirmFunction()
        this.closeModal()
      }
    },
  },
  computed: {
    text () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.text
    },
    payload () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]
    },
  },
  watch: {
    currentComment: function (newVal, prevVal) {
      this.form.comment = newVal
    },
  },
}
</script>

<style scoped>

</style>
