<template>
  <Modal :name="$options.name"
         @close="resetModal"
         :is-loading="isLoading"
         title="Bizottsági válasz módosítás">
    <template #body
              v-if="question">
      <h5 class="title is-5 mb-2 p-1 has-background-primary-light">Kérdés</h5>
      <b>{{ question.number }}. </b>
      <br>
      <div v-html="question.text"></div>
      <h5 class="title is-5 my-2 p-1 has-background-primary-light">Eredeti válasz</h5>
      <div v-if="question.answerOptions.length > 0"
           class="q-answer-options is-flex is-justify-content-center">
        <b-field>
          <AnswerCheckbox v-if="question.answerOptions.includes(1)"
                          :editable="false"
                          :label="getAnswerText(1)"
                          :is-selected="question.originalAnswer === 1"/>
          <AnswerCheckbox v-if="question.answerOptions.includes(0)"
                          :editable="false"
                          :label="getAnswerText(0)"
                          :is-selected="question.originalAnswer === 0"/>
          <AnswerCheckbox v-if="question.answerOptions.includes(2)"
                          :editable="false"
                          :label="getAnswerText(2)"
                          :is-selected="question.originalAnswer === 2"/>
        </b-field>
      </div>
      <h5 class="title is-5 my-2 p-1 has-background-primary-light">Új válasz</h5>
      <div v-if="question.answerOptions.length > 0"
           class="q-answer-options is-flex is-justify-content-center">
        <b-field>
          <AnswerCheckbox v-if="question.answerOptions.includes(1)"
                          @click="answerVal = 1"
                          :label="getAnswerText(1)"
                          :is-selected="answerVal === 1"/>
          <AnswerCheckbox v-if="question.answerOptions.includes(0)"
                          @click="answerVal = 0"
                          :label="getAnswerText(0)"
                          :is-selected="answerVal === 0"/>
          <AnswerCheckbox v-if="question.answerOptions.includes(2)"
                          @click="answerVal = 2"
                          :label="getAnswerText(2)"
                          :is-selected="answerVal === 2"/>
        </b-field>
      </div>
      <h5 class="title is-5 my-2 p-1 has-background-primary-light">Bizottsági megjegyzés</h5>
      <b-field label="">
        <b-input v-model="committeeComment"
                 type="textarea"></b-input>
      </b-field>
    </template>
    <template #footer>
      <b-button @click="modifyAnswer"
                type="is-primary">Módosítás
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import AnswerCheckbox from '@/components/Survey/AnswerCheckbox'
import { getAnswerText } from '@/utils/utils'

export default {
  name: 'SurveyModifyQuestionOriginalAnswer',
  components: {
    AnswerCheckbox,
    Modal,
  },
  data () {
    return {
      isLoading: false,
      answerVal: null,
      committeeComment: null,
    }
  },
  methods: {
    getAnswerText,
    resetModal () {
      this.isLoading = false
      this.answerVal = null
      this.committeeComment = null
    },
    closeModal () {
      this.$store.dispatch('closeModal', this.$options.name)
    },
    async modifyAnswer () {
      this.isLoading = true
      console.log(this.answerVal, this.committeeComment)
      if ([0, 1, 2].includes(Number(this.answerVal)) && this.committeeComment) {
        await this.$store.dispatch('modifyAnswer', {
          surveyID: this.surveyID,
          questionID: this.question.id,
          answer: this.answerVal,
          committeeComment: this.committeeComment,
        })
      } else {
        this.$store.dispatch('showMessage', {
          type: 'warning',
          msg: 'Új válasz és a Bizottsági megjegyzés kitöltése kötelező',
        })
      }
      this.isLoading = false
    },
  },
  computed: {
    inspectionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspectionID
    },
    surveyID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.surveyID
    },
    question () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.question
    },
  },
  watch: {
    question (newValue) {
      if (newValue) {
        if (newValue.isAnswerModified) this.answerVal = newValue.answer
        this.committeeComment = newValue.committeeComment
      }
    },
  },
}
</script>

<style scoped>

</style>
