<template>
  <Modal :name="$options.name"
         @close="clearForm"
         :is-loading="isLoading"
         title="Megjegyzés">
    <template #body>
      <b-field label="Megjegyzés">
        <b-input v-model="form.comment"
                 type="textarea"></b-input>
      </b-field>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="save">
        OK
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'SurveyCommentModal',
  components: {
    Modal,
  },
  data () {
    return {
      form: {
        comment: null,
      },
      isLoading: false,
    }
  },
  methods: {
    async save () {
      this.isLoading = true
      const response = await this.$store.dispatch('setComment', {
        surveyID: this.$route.params.surveyID,
        questionID: this.questionID,
        comment: this.form.comment,
      })
      if (response) {
        this.$store.dispatch('closeModal', this.$options.name)
        this.clearForm()
      }
      this.isLoading = false
    },
    clearForm () {
      this.form.comment = null
    },
  },
  computed: {
    questionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.questionID
    },
    currentComment () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.currentComment
    },
  },
  watch: {
    currentComment: function (newVal, prevVal) {
      this.form.comment = newVal
    },
  },
}
</script>

<style scoped>

</style>
