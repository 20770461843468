<template>
  <Modal :name="$options.name"
         @close="clearForm"
         :is-loading="isLoading"
         title="Hozzáadás a tervhez">
    <template #body>
      <div class="mb-5">
<!--        <div class="columns has-background-grey-lighter">-->
<!--          <div class="column has-text-weight-bold">-->
<!--            Kiválasztva-->
<!--          </div>-->
<!--        </div>-->
        <div class="columns has-background-grey-light">
          <div class="column has-text-weight-bold">Személy</div>
          <div class="column">{{ getSelectedMemberName }}</div>
        </div>
        <div class="columns has-background-grey-lighter">
          <div class="column has-text-weight-bold">Lista</div>
          <div class="column">{{ getSelectedListName }}</div>
        </div>
      </div>
      <b-table
        :data="memberList"
        paginated
        per-page="5"
        :row-class="(row, index) => {
                      return row.id === form.selectedMemberID
                        ? 'has-background-success has-text-white'
                        : ''
                    }"
      >
        <b-table-column field="name" label="Név" searchable v-slot="props">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column field="membership_number" label="Tagszám"  searchable v-slot="props">
          {{ props.row.membership_number }}
        </b-table-column>
        <b-table-column field="id" label="Kiválasztás" v-slot="props">
          <b-button @click="form.selectedMemberID = props.row.id" ű
                    icon-left="check"
                    title="Kiválasztás"
                    type="is-primary"></b-button>
        </b-table-column>
      </b-table>

      <b-field label="Lista választás">
          <b-select v-model="form.selectedListID" placeholder="Válassz egy listát" expanded>
            <option v-for="(item, index) of planList"
                    :key="`inspectionPlanAddMemberModalPlanListSelector_${index}`"
                    :value="item.id">{{ item.name }}</option>
          </b-select>
      </b-field>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="save">
        OK
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'InspectionPlanAddMemberModal',
  components: {
    Modal,
  },
  data () {
    return {
      form: {
        selectedListID: null,
        selectedMemberID: null,
      },
      isLoading: false,
    }
  },
  methods: {
    async save () {
      this.isLoading = true
      const response = await this.$store.dispatch('addMemberToInspectionPlan', {
        memberID: this.form.selectedMemberID,
        listID: this.form.selectedListID,
      })
      if (response) {
        if (this.currentListID === this.form.selectedListID) {

        }
        await this.addItemToTableData(response)
        this.$store.dispatch('closeModal', this.$options.name)
        this.clearForm()
      }
      this.isLoading = false
    },
    clearForm () {
      this.form.selectedListID = null
      this.form.selectedMemberID = null
    },
  },
  computed: {
    addItemToTableData () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.addItemToTableData
    },
    currentListID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.currentListID
    },
    memberList () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.memberList
    },
    planList () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.planList
    },
    getSelectedMemberName () {
      return this.memberList?.find(item => Number(item.id) === Number(this.form.selectedMemberID))?.name || '-'
    },
    getSelectedListName () {
      return this.planList?.find(item => Number(item.id) === Number(this.form.selectedListID))?.name || '-'
    },
  },
  watch: {
    currentListID: function (newVal, prevVal) {
      this.form.selectedListID = newVal
    },
  },
}
</script>

<style scoped>

</style>
