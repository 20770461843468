// eslint-disable-next-line no-unused-vars
import { MKVKRESTPromiseRequest } from '@/utils/utils'

export default {
  state: {},
  mutations: {},
  actions: {
    async fetchInspectionPlanLists ({ rootState }) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_PLAN_LISTS)
    },
    async fetchInspectionPlanTable ({ rootState }, listID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_PLAN_TABLE, {
          section_id: listID,
        })
    },
    async fetchInspectionPlanTableUpdatedRows ({ rootState }, payload) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_PLAN_TABLE, {
          section_id: payload.listID,
          last_modification_date: payload.lastUpdate,
        })
    },
    async fetchInspectionPlanTableWithUpdate ({ rootState }, payload) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_PLAN_TABLE, {
          section_id: payload.listID,
          last_update: payload.lastUpdate,
        })
    },
    async setInspectionPlanComment ({ rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SET_INSPECTION_PLAN_COMMENT, {
          id: payload.rowID,
          comment: payload.comment
        })
      if (response) {
      }
      return response
    },
    async deleteFromInspectionPlan ({ rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DELETE_FROM_INSPECTION_PLAN, {
          id: payload.rowID
        })
      if (response) {
      }
      return response
    },
    async redoDeleteFromInspectionPlan ({ rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.REDO_DELETE_FROM_INSPECTION_PLAN, {
          id: payload.rowID,
        })
      if (response) {
      }
      return response
    },
    async inspectionPlanUniversalSearch ({ rootState }, searchTxt) {
      // return await MKVKRESTPromiseRequest(
      //   rootState.api.INSPECTION_PLAN_UNIVERSAL_SEARCH, {
      //     q: searchTxt,
      //   })
    },
    async fetchInspectionPlanMemberList ({ rootState }) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_PLAN_MEMBER_LIST, {
          q: '',
        })
    },
    async addMemberToInspectionPlan ({ rootState }, payload) {
      return await MKVKRESTPromiseRequest(
        rootState.api.ADD_MEMBER_TO_INSPECTION_PLAN, {
          id: payload.memberID,
          section_id: payload.listID,
        })
    },
  },
  getters: {},
}
