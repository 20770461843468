<template>
  <Modal :name="$options.name"
         :is-loading="isLoading"
         @close="close"
         title="Dokumentáció feltöltés">
    <template v-if="fileList.length > 0"
              #body>
      <div v-for="(inspection, index) in fileList"
           :key="`inspectionFileList_${index}`">
        <h3 class="title is-5">{{ inspection.inspection_type }}</h3>
        <FileList :files="inspection.documents"
                  :has-file-delete="true"
                  @deleteFile="(documentID, fileIndex) => deleteFile(documentID, index, fileIndex)"
                  @openFile="openOrDownloadFile"
                  @downloadFile="openOrDownloadFile"/>
        <b-upload v-model="selectedFile[index]"
                  class="file-label mt-2">
            <span class="file-cta no-border-radius">
                <b-icon class="file-icon"
                        icon="upload"></b-icon>
                <span class="file-label">Fájl kiválasztása</span>
            </span>
          <span class="file-name"
                v-if="selectedFile[index]">
                {{ selectedFile[index].name }}
            </span>
        </b-upload>
        <b-button v-if="selectedFile[index]"
                  @click="uploadFile(inspection.inspection_type_id, index)"
                  class="mt-2"
                  type="is-primary">
          Feltöltés
        </b-button>
      </div>
    </template>
    <template #footer>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import FileList from '@/components/FileList'

export default {
  name: 'InspectionFinalDocumentsAttachModal',
  components: {
    FileList,
    Modal,
  },
  data () {
    return {
      fileList: [],
      isLoading: false,
      selectedFile: [],
    }
  },
  methods: {
    close () {
      this.fileList = []
      this.selectedFile = []
    },
    closeModal () {
      this.$store.dispatch('closeModal', this.$options.name)
    },
    async uploadFile (inspectionTypeID, selectedFileIndex) {
      const reader = new FileReader()
      reader.onloadend = async () => {
        this.isLoading = true
        const uploadResp = await this.$store.dispatch('uploadFinalDocument', {
          inspectionID: this.inspectionID,
          inspectionTypeID: inspectionTypeID,
          filename: this.selectedFile[selectedFileIndex].name,
          file: reader.result.slice(reader.result.indexOf('base64,') + 7),
        })
        if (uploadResp) {
          this.fileList.forEach(inspection => {
            if (Number(inspection.inspection_type_id) === Number(inspectionTypeID)) {
              inspection.documents.push({
                id: uploadResp.id,
                filename: uploadResp.filename,
                // id: '111',
                // filename: this.selectedFile[selectedFileIndex].name,
              })
            }
          })
        }
        this.selectedFile[selectedFileIndex] = null
        this.isLoading = false
      }
      reader.readAsDataURL(this.selectedFile[selectedFileIndex])
    },
    async deleteFile (documentID, inspectionIndex, selectedFileIndex) {
      this.isLoading = true
      const resp = await this.$store.dispatch('deleteFinalDocument', documentID)
      if (resp) {
        await this.$store.dispatch('showMessage', { msg: 'Sikeres törlés', type: 'success' })
        this.fileList[inspectionIndex].documents.splice(selectedFileIndex, 1)
      }
      this.isLoading = false
    },
    async openOrDownloadFile (documentID, action = 'open') {
      this.isLoading = true
      await this.$store.dispatch('downloadFinalDocument',
        {
          documentID: documentID,
          isDownload: action === 'download',
        })
      this.isLoading = false
    },
  },
  computed: {
    inspectionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspectionID
    },
  },
  watch: {
    async inspectionID (newValue) {
      if (newValue) {
        this.isLoading = true
        this.fileList = await this.$store.dispatch('getFinalDocuments', newValue)
        this.isLoading = false
      }
    },
  },
}
</script>
