// eslint-disable-next-line no-unused-vars
import { MKVKRESTPromiseRequest } from '@/utils/utils'

export default {
  state: {},
  mutations: {},
  actions: {
    async fetchInspectableMembers ({ rootState }) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTABLE_MEMBERS)
    },
    async fetchMemberClients ({ rootState }, inspectionID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_MEMBER_CLIENTS, {
          inspection_id: inspectionID,
        })
    },
    async fetchSurveyOptions ({ rootState }, inspectionTypeID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_SURVEY_OPTIONS, {
          inspection_type_id: inspectionTypeID,
        })
    },
    async setInspectionAssign ({ rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SET_INSPECTION_ASSIGN,
        { selection_data: payload })
      if (response) {
      }
      return response
    },
    async deleteInspectionAssign ({ rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DELETE_INSPECTION_ASSIGN, payload)
      if (response) {
      }
      return response
    },
  },
  getters: {},
}
