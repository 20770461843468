<template>
  <b-icon :icon="fileClass"
          :class="`${fileClass}-color`"/>
</template>

<script>
import { getFileIconClass } from '@/utils/utils'

export default {
  name: 'FileIcon',
  props: {
    ext: {
      type: String,
      required: true,
    },
  },
  methods: {
    getFileIconClass,
  },
  computed: {
    fileClass () {
      return this.getFileIconClass(this.ext)
    },
  },
}
</script>

<style scoped>

.file-word-color {
  color: #2b579a;
}

.file-excel-color {
  color: #217346;
}

.file-pdf-color {
  color: #F40F02;
}

.file-image-color {
  color: #e38904;
}
</style>
