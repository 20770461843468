<template>
  <section>
    <b-modal
      :active="$store.state.modal.modalRegistry[name]"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      @close="close"
      @after-enter="$emit('opened')"
      width="600"
      aria-modal>
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head has-background-success">
            <p class="modal-card-title has-text-white">
              <span>{{ title || name }}</span>
              <button
                type="button"
                class="delete mr-2 is-pulled-right"
                @click="close"/>
            </p>
          </header>
          <section class="modal-card-body">
            <slot name="body"></slot>
          </section>
          <footer class="modal-card-foot is-justify-content-flex-end">
            <slot name="footer"></slot>
            <b-button v-if="cancelOnFooter"
                      label="Mégse"
                      @click="close"/>
          </footer>
          <BasicLoading :is-active="isLoading"/>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import BasicLoading from '@/components/Loading'
export default {
  name: 'BasicModal',
  props: {
    name: {
      type: String,
      required: true,
    },
    title: { type: String },
    cancelOnFooter: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
  components: { BasicLoading },
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('close')
      this.$store.dispatch('closeModal', this.name)
    },
    open () {
      this.$store.dispatch('openModal', this.name)
    },
  },
  mounted () {
    this.$store.dispatch('registerModal', this.name)
  },
}
</script>

<style lang="scss"
       scoped>

</style>
