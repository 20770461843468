// eslint-disable-next-line no-unused-vars
import { inspections } from '@/store/testData'
import { downloadFile, MKVKRESTPromiseRequest, openFile, utf8ToB64 } from '@/utils/utils'
import { format as formatDate, isValid, setHours } from 'date-fns'
import router from '@/router'

export default {
  state: {
    // inspections: []
    // TODO: refactor inspections to all?
    list: [],
    selected: {},
  },
  mutations: {
    SET_SELECTED_INSPECTION (state, inspection) {
      state.selected = inspection
    },
    SET_INSPECTION_LIST (state, inspectionList) {
      state.list = inspectionList
    },
    SET_INSPECTION_LIST_ITEM (state, inspection) {
      for (const index in state.list) {
        if (Number(state.list[index].id) === Number(inspection.id)) {
          state.list[index] = inspection
          state.list = [...state.list]
          break
        }
      }
    },
    CLEAR_SELECTED_INSPECTION (state) {
      state.selected = {}
    },
  },
  actions: {
    openInspection ({ commit }, inspectionID) {
      router.push(`/Inspection/${inspectionID}/details`)
    },
    async selectInspection ({ commit, dispatch }, inspectionID) {
      commit('CLEAR_SELECTED_INSPECTION')
      const inspectionDetails = await dispatch('fetchInspectionDetails', inspectionID)
      // const inspectionDetails = true
      if (inspectionDetails) {
        // commit('SET_SELECTED_INSPECTION', testSelectedInspection)
        commit('SET_SELECTED_INSPECTION', inspectionDetails)
      }
    },
    async fetchInspectionList ({ commit, rootState }) {
      const inspections = await MKVKRESTPromiseRequest(rootState.api.GET_INSPECTION_LIST)
      if (inspections) {
        commit('SET_INSPECTION_LIST', inspections)
      }
    },
    async fetchInspectionDetails ({ rootState }, inspectionID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_DETAILS,
        { id: inspectionID })
    },
    async startInspection ({ commit, rootState, dispatch }, inspectionID) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.START_INSPECTION,
        { id: inspectionID })
      if (response) {
        commit('SET_INSPECTION_LIST_ITEM', response)
        dispatch('openInspection', inspectionID)
      }
      return response
    },
    async deleteInspection ({ commit, rootState, dispatch }, inspectionID) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DELETE_INSPECTION,
        { id: inspectionID })
      if (response) {
        commit('SET_INSPECTION_LIST_ITEM', response)
      }
      return response
    },
    async closeInspection ({ commit, rootState, dispatch }, inspectionID) {
      dispatch('startLoading', { loadingKey: 'CLOSE_INSPECTION', loadingText: 'Ellenőrzés lezárása' })
      const checkResponse = await dispatch('checkInspection', inspectionID)
      if (checkResponse.errors.length > 0) {
        dispatch('openModal', {
          modalName: 'InspectionErrorsModal',
          data: {
            errors: checkResponse.errors,
          },
        })
        dispatch('stopLoading', 'CLOSE_INSPECTION')
        return false
      } else {
        const response = await MKVKRESTPromiseRequest(
          rootState.api.CLOSE_INSPECTION,
          { id: inspectionID })
        if (response) {
          commit('SET_SELECTED_INSPECTION', response)
          router.push(`/Inspection/${inspectionID}/details`)
          await dispatch('fetchInspectionList')
        }
        dispatch('stopLoading', 'CLOSE_INSPECTION')
        return response
      }
    },
    async sendInspection ({ commit, rootState, dispatch }, inspectionID) {
      dispatch('startLoading', { loadingKey: 'SEND_INSPECTION', loadingText: 'Ellenőrzés beküldése' })
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SEND_INSPECTION,
        { id: inspectionID })
      dispatch('stopLoading', 'SEND_INSPECTION')
      if (response) {
        commit('SET_INSPECTION_LIST_ITEM', response)
        router.push('/Inspections')
      }
      return response
    },
    async sendBackInspection ({ commit, rootState, dispatch }, inspectionID) {
      dispatch('startLoading', { loadingKey: 'SEND_BACK_INSPECTION', loadingText: 'Ellenőrzés visszaküldése' })
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SEND_BACK_INSPECTION,
        { id: inspectionID })
      dispatch('stopLoading', 'SEND_BACK_INSPECTION')
      if (response) {
        commit('SET_INSPECTION_LIST_ITEM', response)
        router.push('/Inspections')
      }
      return response
    },
    async checkInspection ({ commit, rootState, dispatch }, inspectionID) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.CHECK_INSPECTION,
        { id: inspectionID })
      if (response) {
      }
      return response
    },
    async reOpenInspection ({ commit, rootState, dispatch }, inspectionID) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.REOPEN_INSPECTION,
        { id: inspectionID })
      if (response) {
        commit('SET_SELECTED_INSPECTION', response)
        router.push(`/Inspection/${inspectionID}/details`)
        dispatch('fetchInspectionList')
      }
      return response
    },
    async reportInspectionFinished ({ commit, rootState }, inspectionID) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SET_INSPECTION_TOGGLE_DONE,
        {
          id: inspectionID,
          value: true,
        })
      if (response) {
        commit('SET_INSPECTION_LIST_ITEM', response)
      }
      return response
    },
    async revertInspectionFinished ({ commit, rootState }, inspectionID) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SET_INSPECTION_TOGGLE_DONE,
        {
          id: inspectionID,
          value: false,
        })
      if (response) {
        commit('SET_INSPECTION_LIST_ITEM', response)
      }
      return response
    },
    async generateInspectionDecision ({ commit, rootState, dispatch }, inspectionID) {
      dispatch('startLoading', { loadingKey: 'GENERATE_INSPECTION_DECISION', loadingText: 'Határozat generálása...' })
      const response = await MKVKRESTPromiseRequest(
        rootState.api.GENERATE_INSPECTION_DECISION,
        {
          id: inspectionID,
        })
      if (response) {
        downloadFile(response.content_type, response.file, response.filename)
      }
      dispatch('stopLoading', 'GENERATE_INSPECTION_DECISION')
      return response
    },
    async bookInspectionDate ({ commit, rootState, dispatch }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SET_INSPECTION_DATE,
        {
          id: payload.inspectionID,
          date: payload.date,
          postal_code: payload.zipCode,
          city: payload.city,
          address: payload.address,
        })
      if (response) {
        commit('SET_INSPECTION_LIST_ITEM', response)
      }
      return response
    },
    async fetchInspectionDocument ({ rootState }, payload) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_DOCUMENT,
        {
          id: payload.inspectionID,
          document_id: payload.documentID,
        })
    },
    async setInspectionDocument ({ rootState }, payload) {
      console.log('PAYLOAD', payload)
      return await MKVKRESTPromiseRequest(
        rootState.api.SET_INSPECTION_DOCUMENT,
        {
          id: payload.inspectionID,
          document_id: payload.documentID,
          document: utf8ToB64(payload.document), // base64
        })
    },
    async regenerateInspectionDocument ({ rootState }, payload) {
      return await MKVKRESTPromiseRequest(
        rootState.api.REGENERATE_INSPECTION_DOCUMENT,
        {
          id: payload.inspectionID,
          document_id: payload.documentID,
        })
    },
    async fetchInspectionSummary ({ rootState }, inspectionID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_SUMMARY,
        {
          id: inspectionID,
        })
    },
    async fetchInspectionCommissionDetails ({ rootState }, inspectionID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_COMMISSION_DETAILS,
        {
          id: inspectionID,
        })
    },
    async fetchDocumentRegisterSummary ({ rootState }, inspectionID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_DOCUMENT_REGISTER_SUMMARY,
        {
          id: inspectionID,
          structured_output: true,
        })
    },
    async fetchInspectionBookingLimits ({ rootState }, date) {
      let params = {}
      if (date) {
        params = { date: date }
      }
      return await MKVKRESTPromiseRequest(rootState.api.GET_INSPECTION_BOOKING_LIMITS, params)
    },
    async getFinalDocuments ({ rootState }, inspectionID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_INSPECTION_FINAL_DOCUMENTS,
        {
          id: inspectionID,
        })
    },
    async uploadFinalDocument ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.UPLOAD_INSPECTION_FINAL_DOCUMENT,
        {
          inspection_type_id: payload.inspectionTypeID,
          id: payload.inspectionID,
          filename: payload.filename,
          file: payload.file,
        })
      if (response) {
      }
      return response
    },
    async deleteFinalDocument ({ commit, rootState }, documentID) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DELETE_INSPECTION_FINAL_DOCUMENT,
        {
          id: documentID,
        })
      if (response) {
      }
      return response
    },
    async downloadFinalDocument ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DOWNLOAD_INSPECTION_FINAL_DOCUMENT,
        {
          id: payload.documentID,
        })
      if (response) {
        if (payload?.isDownload) {
          downloadFile(response.content_type, response.file, response.filename)
        } else {
          openFile(response.content_type, response.file)
        }
      }
      return response
    },
    async downloadInspectionPreviewsDecision ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DOWNLOAD_INSPECTION_PREVIEWS_DECISION,
        {
          id: payload.inspectionID,
        })
      if (response) {
        if (payload?.isDownload) {
          downloadFile(response.content_type, response.file, response.filename)
        } else {
          openFile(response.content_type, response.file)
        }
      }
      return response
    },
    async fetchPrintInspectionOptions ({ rootState }, inspectionID) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_PRINT_INSPECTION_OPTIONS,
        {
          id: inspectionID,
        })
    },
    async printInspection ({ rootState, dispatch }, payload) {
      dispatch('startLoading', {
        loadingKey: `INSPECTION_PRINT_${payload.inspectionID}`,
        loadingText: 'Nyomtatás, dokumentum összeállítása...',
      })
      const response = await MKVKRESTPromiseRequest(
        rootState.api.PRINT_INSPECTION,
        {
          id: payload.inspectionID,
          options: payload.printOptions,
        })
      if (response) {
        const win = window.open()
        const base64URL = `data:${response.content_type};base64,${response.file}`
        win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
      }
      dispatch('stopLoading', `INSPECTION_PRINT_${payload.inspectionID}`)
      return response
    },
    async downloadOnlineInspectionFile ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DOWNLOAD_SURVEY_ATTACHED_DOCUMENT,
        {
          id: payload.documentID,
        })
      if (response) {
        if (payload?.isDownload) {
          downloadFile(response.content_type, response.file, response.filename)
        } else {
          openFile(response.content_type, response.file)
        }
      }
      return response
    },
    async downloadOnlineInspectionQuestionAllFile ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DOWNLOAD_ALL_SURVEY_ATTACHED_DOCUMENT,
        {
          survey_id: payload.surveyID,
          question_id: payload.questionID,
        })
      if (response) {
        if (payload?.isDownload) {
          downloadFile(response.content_type, response.file, response.filename)
        } else {
          openFile(response.content_type, response.file)
        }
      }
      return response
    },
  },
  getters: {
    hasSelectedInspection (state) {
      return state.selected && state.selected !== null && Object.keys(state.selected).length > 0
    },
    getInspectionsForCalendar (state) {
      const events = []
      state.list.forEach(inspection => {
        if (isValid(new Date(inspection.date))) {
          events.push({
            start: formatDate(setHours(new Date(inspection.date), 8), 'yyyy-MM-dd HH:mm'),
            end: formatDate(setHours(new Date(inspection.date), 20), 'yyyy-MM-dd HH:mm'),
            title: inspection.name,
            content: inspection.status_name,
            class: 'inspection',
            meta: {
              inspectionID: inspection.id,
            },
          })
        }
      })
      return events
    },
    getSelectedInspectionStatus (state) {
      // eslint-disable-next-line no-prototype-builtins
      return state.selected.hasOwnProperty('status_id') ? state.selected.status_id : null
    },
  },
}
