<template>
  <div id="app">
    <!--    <div id="nav">-->
    <!--      <router-link to="/">Home</router-link> |-->
    <!--    </div>-->
    <TopMenu v-if="$route.name !== 'Login' && $store.state.isLoggedIn()"
             class="mb-3"/>
    <router-view :class="{
      container: $route.meta.hasContainer
    }"/>
    <!-- modals -->
    <Modals/>
    <FullScreenLoading/>
  </div>
</template>

<style lang="scss">

</style>
<script>
import TopMenu from '@/components/TopMenu'
import Modals from '@/components/Modals'
import FullScreenLoading from '@/components/FullScreenLoading'

export default {
  components: {
    FullScreenLoading,
    Modals,
    TopMenu,
  },
  async beforeCreate () {
    // Startup
    if (this.$store.state.isLoggedIn()) {
      await this.$store.dispatch('startLoading', { loadingKey: 'STARTUP' })
      const aliasUser = {
        userID: window.localStorage.getItem('aliasUserID'),
        name: window.localStorage.getItem('aliasUserName'),
      }
      if (aliasUser.userID && aliasUser.name) {
        await this.$store.dispatch('setAliasUser', aliasUser)
      }
      await this.$store.dispatch('fetchInspectionList')
      await this.$store.dispatch('fetchUser')
      await this.$store.dispatch('stopLoading', 'STARTUP')
    }
  },
}
</script>
