<template>
  <Modal :name="$options.name"
         title="Térkép">
    <template #body>
      <iframe v-if="hasAddress"
              width="100%"
              height="450"
              style="border:0"
              :src="getMapsIframeUrl()"
              allowfullscreen></iframe>
      <h3 v-else
          class="title is3">
        Hibás címadat
      </h3>
    </template>
    <template #footer>
      <b-button v-if="hasAddress"
                type="is-primary"
                icon-left="directions"
                @click="getDirection">
        Útvonalterv
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'GoogleMapsModal',
  components: {
    Modal,
  },
  data () {
    return {
      GOOGLE_MAPS_API_KEY: 'AIzaSyC7OsKbikZsIdaGAODaKiQ8M2_rsdGNCNQ',
    }
  },
  methods: {
    getMapsIframeUrl () {
      return `https://www.google.com/maps/embed/v1/place?key=${this.GOOGLE_MAPS_API_KEY}&q=
      ${encodeURI(this.$store.state.modal.modalPayloadData[this.$options.name].address)}`
    },
    getDirection () {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=
        ${this.$store.state.modal.modalPayloadData[this.$options.name].address}`,
        '_blank')
    },
  },
  computed: {
    hasAddress () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.address
    },
  },
}
</script>

<style scoped>

</style>
