<template>
  <div class="loading-container">
    <b-loading :is-full-page="isFull"
               :active="isActive"
               :can-cancel="false"></b-loading>
  </div>
</template>

<script>
export default {
  name: 'BasicLoading',
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
    isFull: {
      type: Boolean,
      default: false,
    }
  },
  components: {},
}
</script>

<style lang="scss"
       scoped>
@import "@/assets/css/_custom-variables.scss";
.loading-container::v-deep .loading-icon:after {
  border-left-color: $primary;
  border-bottom-color: $primary;
}
</style>
