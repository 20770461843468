<template>
  <Modal :name="$options.name"
         @close="clearForm"
         :is-loading="isLoading"
         title="Dokumentumjegyzék">
    <template #body>
      <div v-if="documentRegister && !isModifyDocumentRegisterNameMode">
        <div class="has-background-grey-lighter p-2">
          <span class="is-size-5">{{ documentRegister.name }}</span>
          <b-button type="is-ghost"
                    @click="editDocumentRegisterName"
                    class="has-text-grey text-button-height-modifier is-pulled-right"
                    icon-left="edit"/>
        </div>
        <div class="my-3">
          <FileList :files="documentRegister.documents"
                    :has-file-delete="true"
                    @openFile="openOrDownloadFile"
                    @downloadFile="openOrDownloadFile"
                    @deleteFile="deleteFileFromDocumentRegister"/>
        </div>
        <div>
          <b-field class="file is-primary"
                   :class="{'has-name': !!selectedFile}">
            <b-upload v-model="selectedFile"
                      class="file-label">
            <span class="file-cta no-border-radius">
                <b-icon class="file-icon"
                        icon="upload"></b-icon>
                <span class="file-label">Fájl Feltöltés</span>
            </span>
            </b-upload>
          </b-field>
        </div>
      </div>
      <div v-else>
        <b-field label="Dokumentumjegyzék nevének megadása">
          <b-input v-model="documentRegisterName"
                   placeholder="Jegyzék név..."></b-input>
        </b-field>
        <b-button type="is-primary"
                  @click="saveDocumentRegisterName"
                  class="is-pulled-right">
          Dokumentumjegyzék mentése
        </b-button>
      </div>
    </template>
    <template #footer>
      <b-button v-if="documentRegister"
                type="is-primary"
                @click="attachExistedDocument">
        Meglévő dokumentum csatolása
      </b-button>
      <b-button v-if="documentRegister"
                type="is-danger"
                @click="deleteDocumentumRegistry">
        Dokumentumjegyzék törlése
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import { getFileIconClass } from '@/utils/utils'
import FileList from '@/components/FileList'

export default {
  name: 'DocumentRegisterModal',
  components: {
    FileList,
    Modal,
  },
  data () {
    return {
      selectedFile: null,
      documentRegisterName: '',
      isModifyDocumentRegisterNameMode: false,
      isLoading: false,
    }
  },
  methods: {
    getFileIconClass,
    clearForm () {
      this.selectedFile = null
      this.documentRegisterName = ''
      this.isModifyDocumentRegisterNameMode = false
    },
    deleteDocumentumRegistry () {
      this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos törli a teljes dokumentumjegyzéket?',
          confirmFunction: async () => {
            this.isLoading = true
            await this.$store.dispatch('deleteDocumentRegister', {
              surveyID: this.surveyID,
              questionID: this.questionID,
            })
            this.clearForm()
            this.$store.dispatch('closeModal', this.$options.name)
            this.isLoading = false
          },
        },
      })
    },
    attachExistedDocument () {
      this.$store.dispatch('openModal', {
        modalName: 'AttachedDocumentsTreeModal',
        data: {
          questionID: this.questionID,
          isSurveyAttachMode: true,
          userAttachedDocuments: this.userAttachedDocuments,
          attachedDocuments: this.$store.state.inspection.selected.external_documents,
        },
      })
    },
    editDocumentRegisterName () {
      this.documentRegisterName = this.documentRegister.name
      this.isModifyDocumentRegisterNameMode = true
    },
    async saveDocumentRegisterName () {
      this.isLoading = true
      await this.$store.dispatch('setDocumentRegisterName', {
        surveyID: this.surveyID,
        questionID: this.questionID,
        documentRegisterName: this.documentRegisterName,
      })
      this.isModifyDocumentRegisterNameMode = false
      this.isLoading = false
    },
    async uploadFile () {
      const reader = new FileReader()
      reader.onloadend = async () => {
        this.isLoading = true
        await this.$store.dispatch('uploadFileToDocumentRegister', {
          surveyID: this.surveyID,
          questionID: this.questionID,
          filename: this.selectedFile.name,
          // file: Buffer.from(reader.result).toString('base64'),
          file: reader.result.slice(reader.result.indexOf('base64,') + 7),
          // file: reader.result,
        })
        this.isLoading = false
      }
      reader.readAsDataURL(this.selectedFile)
    },
    async openOrDownloadFile (documentID, action = 'open') {
      this.isLoading = true
      if (['download', 'open'].includes(action)) {
        await this.$store.dispatch('downloadFileFromDocumentRegister', {
          documentID: documentID,
          isDownload: action === 'download',
        })
      }
      this.isLoading = false
    },
    async deleteFileFromDocumentRegister (documentID) {
      await this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos törli a dokumentumot?',
          confirmFunction: async () => {
            this.isLoading = true
            await this.$store.dispatch('deleteFileFromDocumentRegister', {
              documentID: documentID,
              questionID: this.questionID,
            })
            this.isLoading = false
          },
        },
      })

      /*
      this.isLoading = true
      if (['download', 'open'].includes(action)) {
        await this.$store.dispatch('downloadFileFromDocumentRegister', {
          documentID: documentID,
          isDownload: action === 'download',
        })
      }
      this.isLoading = false
       */
    },
  },
  computed: {
    documentRegister () {
      return this.$store.getters.getDocumentRegister(this.questionID)
      // return this.$store.state.modal.modalPayloadData[this.$options.name]?.documentRegister
    },
    questionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.questionID
    },
    userAttachedDocuments () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.userAttachedDocuments
    },
    surveyID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.surveyID
    },
  },
  watch: {
    selectedFile: function (newVal, prevVal) {
      if (newVal && typeof newVal === 'object' && newVal?.name) {
        this.uploadFile()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
