<template>
  <Modal :name="$options.name"
         @close="resetModal"
         title="TesztModal">
    <template #body></template>
    <template #footer></template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'TestModal',
  components: {
    Modal,
  },
  methods: {
    closeModal () {
      this.$store.dispatch('closeModal', this.$options.name)
    },
    resetModal () {}
  },
  computed: {
    someData () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.someData
    },
  },
}
</script>

<style scoped>

</style>
