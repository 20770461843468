<template>
  <Modal :name="$options.name"
         title="Hiba az ellenőrzésben">
    <template #body>
      <div v-for="(err, index) in errors"
           :key="`inspectionErrorsModal_${index}`"
           class="columns has-background-warning has-text-weight-bold">
        <div class="column is-6">
          {{ err.row }}
        </div>
        <div class="column is-6">
          {{ err.message }}
        </div>
      </div>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="closeModal">
        Ok
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'InspectionErrorsModal',
  components: {
    Modal,
  },
  methods: {
    closeModal () {
      this.$store.dispatch('closeModal', this.$options.name)
    },
  },
  computed: {
    errors () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.errors
    },
  },
}
</script>

<style scoped>

</style>
