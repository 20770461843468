<template>
  <div class="vld-parent">
    <loading :active="$store.getters.isFullScreenLoadingActive"
             :can-cancel="false"
             :opacity="1"
             color="#36599E"
             :lock-scroll="true"
             :is-full-page="true">
      <template #after>
        {{ $store.state.loading.text }}
      </template>
    </loading>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'FullScreenLoading',
  components: {
    Loading,
  },
  methods: {},
}
</script>

<style lang="scss"
       scoped>
</style>
