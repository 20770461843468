var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"gradient-navbar",scopedSlots:_vm._u([{key:"brand",fn:function(){return [_c('router-link',{staticClass:"is-disabled",attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{staticClass:"has-text-white",attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_c('img',{attrs:{"src":"/img/logo_v3.png","alt":"Minell"}}),_c('span',{staticClass:"ml-1 has-text-weight-bold"},[_vm._v("Minell")]),_c('span',{staticClass:"has-text-grey is-size-7 ml-1"},[_vm._v("v"+_vm._s(_vm.VERSION))]),(_vm.NODE_ENV !== 'production')?_c('b-field',{staticClass:"ml-2"},[_c('b-tag',{attrs:{"type":"is-danger"}},[_vm._v(_vm._s(_vm.NODE_ENV))])],1):_vm._e()],1)]}}])})]},proxy:true},{key:"start",fn:function(){return [_c('router-link',{staticClass:"is-disabled",attrs:{"to":"/News","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Hírek ")])]}}])}),(_vm.$store.getters.minUserLevelInspector)?_c('router-link',{staticClass:"is-disabled",attrs:{"to":"/Calendar","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Naptár ")])]}}],null,false,491966931)}):_vm._e(),(_vm.$store.getters.minUserLevelLawyer)?_c('router-link',{staticClass:"is-disabled",attrs:{"to":"/Inspections","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Ellenőrzések ")])]}}],null,false,3152904424)}):_vm._e(),(_vm.$store.getters.minUserLevelGroupLeader)?_c('router-link',{staticClass:"is-disabled",attrs:{"to":"/inspection-assign","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Ellenőrzés kiválasztása ")])]}}],null,false,3526422448)}):_vm._e(),(_vm.$store.getters.minUserLevelGroupLeader)?_c('router-link',{staticClass:"is-disabled",attrs:{"to":"/inspection-plan","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Ellenőrzési terv ")])]}}],null,false,3349413754)}):_vm._e(),(_vm.$store.getters.minUserLevelAdmin)?_c('b-navbar-dropdown',{attrs:{"label":"Admin"}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Visszaküldés az ellenőrnek ")])]}}],null,false,1133522300)}),_c('b-navbar-item',{on:{"click":function($event){return _vm.$store.dispatch('openModal', 'CreateNewsModal')}}},[_vm._v(" Új hír ")]),_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Kérdőív szerkesztő ")])]}}],null,false,803295081)})],1):_vm._e(),(_vm.NODE_ENV === 'development' && _vm.$store.getters.minUserLevelAdmin)?_c('b-navbar-dropdown',{attrs:{"label":"For Test"}},[_c('router-link',{attrs:{"to":"/Inspection/10/details","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Ellenőrzés ")])]}}],null,false,3537433094)}),_c('router-link',{attrs:{"to":"/NotFound","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" 404 Not Found ")])]}}],null,false,371061176)}),_c('router-link',{attrs:{"to":"/ModalTesterPage","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" ModalTesterPage ")])]}}],null,false,2040409650)}),_c('router-link',{attrs:{"to":"/test1","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Test page 1 ")])]}}],null,false,2834322559)}),_c('router-link',{attrs:{"to":"/test2","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Test page 2 ")])]}}],null,false,3394919068)}),_c('router-link',{attrs:{"to":"/test3","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":navigate}},[_vm._v(" Test page 3 ")])]}}],null,false,3412527165)})],1):_vm._e()]},proxy:true},{key:"end",fn:function(){return [_c('b-navbar-dropdown',{attrs:{"label":_vm.$store.getters.userName}},[(_vm.$store.getters.minUserLevelGroupLeader)?_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive},on:{"click":function($event){return _vm.$store.dispatch('openModal', 'InspectorSelectModal')}}},[_vm._v(" Ellenőr választás ")])]}}],null,false,713776634)}):_vm._e(),_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-navbar-item',{attrs:{"active":isActive,"href":href},on:{"click":_vm.logOut}},[_vm._v(" Kijelentkezés ")])]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }