<template>
  <Modal :name="$options.name"
         :is-loading="isLoading"
         @close="clearForm"
         title="Ellenőrzés időpont foglalás">
    <template #body>
      <b-field label="Ellenőrzés Dátuma">
        <b-datepicker
          v-model="form.date"
          :show-week-number="false"
          :locale="undefined"
          required
          placeholder="Válassz dátumot"
          icon="calendar"
          :icon-right="form.date ? 'close-circle' : ''"
          icon-right-clickable
          @icon-right-click="() => form.date = null"
          @input="fetchMonthBookingLimit"
          trap-focus>
        </b-datepicker>
      </b-field>
      <div class="has-background-grey-lighter px-2 py-1">
        Az ellenőrzéshez <strong>{{ inspectionBookCount }}</strong> szabad időpont szükséges
        <div v-if="bookingLimit">
          Erre a hónapra (<span class="is-capitalized">{{ getMonthNameByNumber (bookingLimit.honap)}}</span>) még
          <strong :class="`has-text-${getInspectionBookingLimitColor(bookingLimit.db, bookingLimit.db - bookingLimit.available_inspection_count)}`">
            {{ bookingLimit.available_inspection_count }}
          </strong>
          szabad időpont van.
        </div>
      </div>
      <b-field label="IRSZ">
        <b-input v-model="form.zipCode"
                 @keyup.enter.native="isLoading ? null : save()"
                 ref="formZipCode"
                 required></b-input>
      </b-field>
      <b-field label="Város">
        <b-input v-model="form.city"
                 @keyup.enter.native="isLoading ? null : save()"
                 ref="formCity"
                 required></b-input>
      </b-field>
      <b-field label="Cím">
        <b-input v-model="form.address"
                 @keyup.enter.native="isLoading ? null : save()"
                 ref="formAddress"
                 required></b-input>
      </b-field>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="save">
        Időpont mentése
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import { isValid as isValidDate, format as formatDate } from 'date-fns'
import { getMonthNameByNumber, getInspectionBookingLimitColor } from '@/utils/utils'

export default {
  name: 'InspectionBookDateModal',
  components: {
    Modal,
  },
  data () {
    return {
      isLoading: false,
      form: {
        zipCode: null,
        city: null,
        address: null,
        date: null,
      },
      bookingLimit: null,
    }
  },
  methods: {
    getMonthNameByNumber,
    getInspectionBookingLimitColor,
    clearForm () {
      this.form.zipCode = null
      this.form.city = null
      this.form.address = null
      this.form.date = null
      this.bookingLimit = null
    },
    async save () {
      this.isLoading = true
      const dateIsValid = isValidDate(this.form.date)
      const zipCodeIsValid = this.$refs.formZipCode.checkHtml5Validity()
      const cityIsValid = this.$refs.formCity.checkHtml5Validity()
      const addressIsValid = this.$refs.formAddress.checkHtml5Validity()
      if (dateIsValid && zipCodeIsValid && cityIsValid && addressIsValid) {
        const resp = await this.$store.dispatch('bookInspectionDate', {
          inspectionID: this.inspectionID,
          zipCode: this.form.zipCode,
          city: this.form.city,
          address: this.form.address,
          date: formatDate(this.form.date, 'yyyy-MM-dd'),
        })
        if (resp) {
          await this.$store.dispatch('closeModal', this.$options.name)
          this.clearForm()
        }
      }
      if (!dateIsValid) {
        await this.$store.dispatch('showMessage', { type: 'danger', msg: 'A dátum mező kitöltése kötelező' })
      }
      this.isLoading = false
    },
    async fetchMonthBookingLimit () {
      if (this.form.date) {
        const resp = await this.$store.dispatch('fetchInspectionBookingLimits', this.form.date)
        if (resp.length > 0) { this.bookingLimit = resp[0] }
      }
    }
  },
  computed: {
    inspectionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspectionID
    },
    inspectionBookCount () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspectionBookCount
    },
  },
}
</script>
