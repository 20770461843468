<template>
  <Modal :name="$options.name"
         @close="clearData"
         @opened="opened"
         title="Ellenőrzés részletei">
    <template #body>
      <div v-if="isLoaded">
        <b-table :data="inspectors">
          <b-table-column label="Név"
                          field="name"
                          v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Tagszám"
                          field="membership_number"
                          v-slot="props">
            {{ props.row.membership_number }}
          </b-table-column>
          <b-table-column label=""
                          field="id"
                          v-slot="props">
            <b-button type="is-primary"
                      @click="selectInspector(props.row.id, props.row.name)"
                      class="text-button-height-modifier">
              Kiválaszt
            </b-button>
          </b-table-column>
        </b-table>
      </div>
      <div v-else>
        <Loading :is-active="true"/>
      </div>
    </template>
    <template #footer>
      <b-button type="is-primary" @click="resetAliasInspector">Visszaállítás (Saját felhasználó kiválasztása)</b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import Loading from '@/components/Loading'

export default {
  name: 'InspectorSelectModal',
  components: {
    Modal,
    Loading,
  },
  data () {
    return {
      inspectors: [],
      isLoaded: false,
    }
  },
  methods: {
    async resetAliasInspector () {
      await this.$store.dispatch('clearAliasUser')
      window.location.replace('/')
    },
    async selectInspector (userID, name) {
      // TODO: ne tudja önmagát kiválasztani
      await this.$store.dispatch('setAliasUser', {
        userID: userID,
        name: name,
      })
      window.location.replace('/')
    },
    clearData () {
      this.inspectors = []
      this.isLoaded = false
    },
    async opened () {
      this.clearData()
      const resp = await this.$store.dispatch('fetchInspectorsList')
      if (resp) {
        this.inspectors = resp
      }
      this.isLoaded = true
    },
  },
  computed: {
    // inspectionID () {
    //   return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspectionID
    // },
  },
}
</script>

<style scoped>

</style>
