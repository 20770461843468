import { render, staticRenderFns } from "./SurveyCommentModal.vue?vue&type=template&id=e6805212&scoped=true&"
import script from "./SurveyCommentModal.vue?vue&type=script&lang=js&"
export * from "./SurveyCommentModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6805212",
  null
  
)

export default component.exports