
export const isLoggedIn = () => {
  return !!window.localStorage.jwt
}

export const logIn = (jwt) => {
  window.localStorage.setItem('jwt', jwt)
  window.location.reload()
}

export const logOut = () => {
  window.localStorage.removeItem('jwt')
  window.localStorage.removeItem('aliasUserID')
  window.localStorage.removeItem('aliasUserName')
  // noinspection JSCheckFunctionSignatures
  window.location.reload(true)
}
