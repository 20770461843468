<template>
  <div class="q-answer-checkbox"
       :class="{'q-selected': isSelected, 'not-editable': !editable, 'is-loading': isLoading }"
       @click="checkboxClicked"
       type="is-primary">
    <b-icon v-if="isSelected"
            pack="far"
            icon="check-circle"></b-icon>
    <b-icon v-else-if="editable"
            pack="far"
            icon="circle"></b-icon>
    <span class="user-select-none">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'AnswerCheckbox',
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkboxClicked () {
      if (this.editable) {
        if (!this.isLoading) {
          this.$emit('click')
        }
      }
    },
  },
}
</script>

<style lang="scss"
       scoped>
@import "../../assets/css/_custom-variables.scss";

.q-answer-checkbox {
  padding: .5rem 1rem;
  margin: 0 .5rem;
  font-weight: 500;
  border: 1px solid $primary;
  cursor: pointer;

  &:hover {
    opacity: .9;
  }

  &.not-editable {
    border: none !important;
    background: none !important;
    color: $dark-grey !important;
  }

  &.is-loading {
    opacity: .8;
    cursor: not-allowed;
  }

  &.q-selected {
    background: $primary;
    color: $white;
  }
}
</style>
