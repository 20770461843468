import Vue from 'vue'
import Vuex from 'vuex'
import modal from '@/store/modal'
import inspection from '@/store/inspection'
import message from '@/store/message'
import { MKVKRESTPromiseRequest, pingServer } from '@/utils/utils'
import { isLoggedIn } from '@/utils/auth'
import survey from '@/store/survey'
import api from '@/store/api'
import inspectionAssign from '@/store/inspectionAssign'
import inspectionPlan from '@/store/inspectionPlan'
import { sha1 } from 'js-sha1'

// import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: isLoggedIn,
    loading: {
      isActive: {},
      text: null,
    },
    api_key: 'a16e5981-0960-49be-8df4-80af608a5a3e',
    adminAliasUser: null,
    user: {
      userLevel: 40,
      name: 'Test Béla',
    },
  },
  mutations: {
    START_LOADING (state, payload) {
      // noinspection JSUnresolvedVariable
      state.loading.text = payload?.loadingText ? payload.loadingText : null
      state.loading.isActive[payload.loadingKey] = true
      state.loading = { ...state.loading }
    },
    STOP_LOADING (state, loadingKey) {
      delete state.loading.isActive[loadingKey]
      state.loading = { ...state.loading }
      state.loading.text = null
    },
    SET_USER (state, user) {
      state.user.userLevel = user.user_level
      state.user.name = user.name
    },
    SET_ALIAS_USER (state, user) {
      state.adminAliasUser = {
        ID: user.userID,
        name: user.name,
      }
    },
    CLEAR_ALIAS_USER (state, user) {
      state.adminAliasUser = null
    },
  },
  actions: {
    async fetchInspectorsList ({ state }) {
      return await MKVKRESTPromiseRequest(state.api.GET_INSPECTOR_LIST)
    },
    async fetchUser ({ commit, state }, payload) {
      const response = await MKVKRESTPromiseRequest(state.api.GET_USER)
      if (response) {
        commit('SET_USER', response)
      }
    },
    async setAliasUser ({ commit, dispatch }, payload) {
      commit('SET_ALIAS_USER', payload)
      window.localStorage.setItem('aliasUserID', payload.userID)
      window.localStorage.setItem('aliasUserName', payload.name)
      // dispatch('fetchInspectionList')
    },
    async clearAliasUser ({ commit }) {
      window.localStorage.removeItem('aliasUserID')
      window.localStorage.removeItem('aliasUserName')
      commit('CLEAR_ALIAS_USER')
    },
    startLoading ({ commit }, payload) {
      commit('START_LOADING', payload)
    },
    stopLoading ({ commit }, loadingKey) {
      commit('STOP_LOADING', loadingKey)
    },
    async fetchRegions ({ rootState }) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_REGIONS)
    },
    async fetchQualifications ({ rootState }) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_QUALIFICATIONS)
    },
    async fetchSpecializations ({ rootState }) {
      return await MKVKRESTPromiseRequest(
        rootState.api.GET_SPECIALIZATIONS)
    },
    async pingLocalServer ({ rootState }) {
      return await pingServer(rootState.api.LOCAL_URL)
    },
    async loginWithEmailAndPwd ({ rootState }, payload) {
      return await MKVKRESTPromiseRequest(
        rootState.api.LOGIN_WITH_EMAIL_AND_PWD,
        {
          email: payload.email,
          pwd: sha1(payload.pwd),
        },
        true,
      )
    },
  },
  getters: {
    minUserLevelLawyer (state) {
      return state.user && state.user.userLevel >= 10
    },
    minUserLevelInspector (state) {
      return state.user && state.user.userLevel >= 20
    },
    minUserLevelGroupLeader (state) {
      return state.user && state.user.userLevel >= 30
    },
    minUserLevelAdmin (state) {
      return state.user && state.user.userLevel >= 40
    },
    isLawyer (state, getters) {
      console.log('USER LEVEL', state.user.userLevel)
      return (state.user && state.user.userLevel === 10) || getters.isAdmin
    },
    isInspector (state, getters) {
      return (state.user && state.user.userLevel === 20) || getters.isAdmin
    },
    isGroupLeader (state, getters) {
      return (state.user && state.user.userLevel === 30) || getters.isAdmin
    },
    isAdmin (state, getters) {
      return (state.user && state.user.userLevel === 40)
    },
    userName (state) {
      return state.adminAliasUser ? `${state.user.name} (${state.adminAliasUser.name})` : state.user.name
    },
    isFullScreenLoadingActive (state) {
      return !!Object.values(state.loading.isActive).find(i => i === true)
    },
  },
  modules: {
    modal,
    inspection,
    message,
    survey,
    api,
    inspectionAssign,
    inspectionPlan,
  },
})
