import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    LOCAL_URL: 'https://api-qc.mkvk.hu/',
    BASE_URL: 'https://api-qc.mkvk.hu/',
    BASE_URL_DEV: 'https://api-qc-test.mkvk.hu/',
    GET_INSPECTOR_LIST: 'qc/inspector_list',
    GET_USER: 'qc/inspector',
    START_INSPECTION: 'qc/inspection/start',
    DELETE_INSPECTION: 'qc/inspection/delete',
    CLOSE_INSPECTION: 'qc/inspection/close',
    SEND_INSPECTION: 'qc/inspection/send',
    SEND_BACK_INSPECTION: 'qc/inspection/send_back',
    CHECK_INSPECTION: 'qc/inspection/check',
    REOPEN_INSPECTION: 'qc/inspection/reopen',
    SET_INSPECTION_DATE: 'qc/inspection/set_date',
    GET_INSPECTION_LIST: 'qc/inspection/list',
    GET_INSPECTION_DETAILS: 'qc/inspection/details',
    GET_INSPECTION_COMMISSION_DETAILS: 'qc/inspection/commission_details',
    GET_INSPECTION_SUMMARY: 'qc/inspection/summary',
    GET_INSPECTION_DOCUMENT: 'qc/inspection/document/document',
    SET_INSPECTION_DOCUMENT: 'qc/inspection/document/set_document',
    GET_INSPECTION_FINAL_DOCUMENTS: 'qc/inspection/documentation/list',
    GET_INSPECTION_BOOKING_LIMITS: 'qc/inspection/reservation_calendar',
    SET_INSPECTION_TOGGLE_DONE: 'qc/inspection/set_decision_done',
    GENERATE_INSPECTION_DECISION: 'qc/inspection/decision_generate',
    UPLOAD_INSPECTION_FINAL_DOCUMENT: 'qc/inspection/documentation/upload',
    DOWNLOAD_INSPECTION_FINAL_DOCUMENT: 'qc/inspection/documentation/download',
    DOWNLOAD_INSPECTION_PREVIEWS_DECISION: '/inspection/decision_download',
    DELETE_INSPECTION_FINAL_DOCUMENT: 'qc/inspection/documentation/delete',
    REGENERATE_INSPECTION_DOCUMENT: 'qc/inspection/document/regenerate_document',
    GET_SURVEY: 'qc/inspection/survey/survey',
    GET_SURVEY_ATTACHED_DOCUMENTS: 'qc/inspection/survey/external_documents/tree', // TODO: survey_id
    // GET_SURVEY_ATTACHED_DOCUMENTS: 'qc/inspection/survey/external_documents/download',
    DOWNLOAD_SURVEY_ATTACHED_DOCUMENT: 'qc/inspection/survey/external_documents/download',
    DOWNLOAD_ALL_SURVEY_ATTACHED_DOCUMENT: 'qc/inspection/survey/external_documents/download_all',
    SET_SURVEY_QUESTION_ANSWER: 'qc/inspection/survey/question/set_answer',
    MODIFY_SURVEY_QUESTION_ANSWER: 'qc/inspection/survey/question/modify_answer',
    RESTORE_SURVEY_QUESTION_ANSWER: 'qc/inspection/survey/question/restore_answer',
    SET_SURVEY_QUESTION_COMMENT: 'qc/inspection/survey/question/set_comment',
    SET_SURVEY_QUESTION_DOCUMENT_REGISTER_NAME: 'qc/inspection/survey/question/documents/set_name',
    SET_SURVEY_EXTRA_QUESTION: 'qc/inspection/survey/question/set_extra_question_answer',
    SET_SURVEY_ALL_ANSWER: 'qc/inspection/survey/fill',
    DELETE_SURVEY_QUESTION_DOCUMENT_REGISTER: 'qc/inspection/survey/question/documents/list_delete',
    DOWNLOAD_SURVEY_QUESTION_DOCUMENT_REGISTER_FILE: 'qc/inspection/survey/question/documents/download',
    UPLOAD_SURVEY_QUESTION_DOCUMENT_REGISTER_FILE: 'qc/inspection/survey/question/documents/upload',
    ATTACH_SURVEY_QUESTION_DOCUMENT_REGISTER_FILE: 'qc/inspection/survey/question/documents/external_upload',
    DELETE_FILE_FROM_SURVEY_QUESTION_DOCUMENT_REGISTER: 'inspection/survey/question/documents/delete',
    GET_INSPECTION_DOCUMENT_REGISTER_SUMMARY: 'qc/inspection/document_register',
    GET_PRINT_INSPECTION_OPTIONS: 'qc/inspection/pdf_options',
    PRINT_INSPECTION: 'qc/inspection/pdf',
    GET_INSPECTABLE_MEMBERS: 'qc/selection/list',
    GET_MEMBER_CLIENTS: 'qc/selection/commission_list',
    GET_REGIONS: 'dictionary/szervezet',
    GET_QUALIFICATIONS: 'dictionary/minositestipus',
    GET_SPECIALIZATIONS: 'dictionary/szakterulet_tipus',
    GET_SURVEY_OPTIONS: 'qc/selection/questionnaire_list',
    SET_INSPECTION_ASSIGN: 'qc/selection/save',
    DELETE_INSPECTION_ASSIGN: 'qc/selection/delete',
    GET_INSPECTION_PLAN_LISTS: 'qc/plan/section_list',
    GET_INSPECTION_PLAN_TABLE: 'qc/plan/list',
    DELETE_FROM_INSPECTION_PLAN: 'qc/plan/delete',
    REDO_DELETE_FROM_INSPECTION_PLAN: 'qc/plan/undelete',
    SET_INSPECTION_PLAN_COMMENT: 'qc/plan/comment',
    INSPECTION_PLAN_UNIVERSAL_SEARCH: 'qc/plan/universal_search',
    GET_INSPECTION_PLAN_MEMBER_LIST: 'qc/plan/member_search',
    ADD_MEMBER_TO_INSPECTION_PLAN: 'qc/plan/add_member',
    LOGIN_WITH_EMAIL_AND_PWD_LOCAL: 'qc/',
    LOCAL_PING: 'qc/',
  },
  mutations: {},
  actions: {},
})
