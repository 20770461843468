<template>
  <div>
    <ul v-for="(item, index) in tree"
        :key="`tree_${index}_${item.name}`">
      <li @click="folderClick(index)"
          :class="{'pointer-cursor': item.childs.length > 0 || item.documents.length > 0}">
        <span v-if="item.childs.length > 0 || item.documents.length > 0">
          <b-icon v-if="!show[index]"
                  icon="plus"></b-icon>
        <b-icon v-else
                icon="minus"></b-icon>
        </span>
        <b-icon icon="folder"/>
        <span>{{ item.name }}</span>
      </li>
      <FileTree v-if="item.childs.length > 0"
                v-show="show[index]"
                :tree="item.childs">
        <template v-slot:fileExtraSlot="props">
          <slot v-bind="props"
                name="fileExtraSlot"></slot>
        </template>
      </FileTree>
      <ul v-if="item.documents.length > 0"
          v-show="show[index]">
        <li v-for="(document, docIndex) in item.documents"
            :key="`document_tree_${item.name}_${document.id}_${docIndex}`">
<!--          <b-icon :icon="getFileIconClass(document.ext)"/>-->
          <FileIcon :ext="'png'"/>
          <span class="file-name-container">{{ document.name }}</span>
          <span>
            <slot v-bind:document="document"
                  name="fileExtraSlot"></slot>
            <b-button type="is-ghost"
                      icon-left="eye"
                      @click="openOrDownloadFile(document.id, 'open')"
                      class="text-button-height-modifier is-pulled-right">
            </b-button>
            <b-button type="is-ghost"
                      icon-left="file-download"
                      @click="openOrDownloadFile(document.id, 'download')"
                      class="text-button-height-modifier is-pulled-right">
            </b-button>
          </span>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script>
import { getFileIconClass } from '@/utils/utils'
import FileIcon from '@/components/FileIcon'

export default {
  name: 'FileTree',
  components: { FileIcon },
  props: {
    tree: {
      required: true,
    },
  },
  data () {
    return {
      show: [],
    }
  },
  methods: {
    getFileIconClass,
    folderClick (index) {
      this.show[index] = !this.show[index]
      this.show = [...this.show]
    },
    async openOrDownloadFile (documentID, action = 'open') {
      this.isLoading = true
      if (['download', 'open'].includes(action)) {
        await this.$store.dispatch('downloadOnlineInspectionFile', {
          documentID: documentID,
          isDownload: action === 'download',
        })
      }
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
ul {
  margin-left: 1rem;
}

li {
  user-select: none;
}

.file-name-container {
  display: inline-block;
  width: 60%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
