import { ToastProgrammatic as Toast, SnackbarProgrammatic as Snackbar } from 'buefy'

const DEFAULT_MESSAGE = 'Váratlan hiba történt, kérjük próbálja meg később!'
const DEFAULT_DURATION = 5000
const DEFAULT_POSITION = 'top-right'
const DEFAULT_TYPE = 'warning'
const DEFAULT_INDEFINITE = false
const DEFAULT_ACTION_TEXT = 'OK'
const VALID_POSITIONS = ['top-right', 'top', 'top-left', 'bottom-right', 'bottom', 'bottom-left']
const VALID_TYPES = ['white', 'black', 'light', 'dark', 'primary', 'info', 'success', 'warning', 'danger']

export default {
  state: {},
  mutations: {},
  actions: {
    showMessage ({ state }, {
      type = DEFAULT_TYPE,
      msg = DEFAULT_MESSAGE,
      duration = DEFAULT_DURATION,
      position = DEFAULT_POSITION,
      indefinite = DEFAULT_INDEFINITE,
      actionText = DEFAULT_ACTION_TEXT,
      onAction = null,
      cancelText = null,
    }) {
      if (!VALID_TYPES.includes(type)) type = DEFAULT_TYPE
      if (!VALID_POSITIONS.includes(position)) position = DEFAULT_POSITION
      const msgObj = {
        type: `is-${type}`,
        message: msg,
        duration: duration,
        position: `is-${position}`,
        indefinite: indefinite,
        actionText: actionText,
        onAction: onAction,
        cancelText: cancelText,
      }

      if (typeof onAction === 'function') {
        Snackbar.open(msgObj)
      } else {
        Toast.open(msgObj)
      }
    },
  },
  getters: {},
}
