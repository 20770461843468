<template>
  <Modal :name="$options.name"
         @close="closeListener"
         :is-loading="isLoading"
         title="Kérdés">
    <template v-if="extraQuestion" #body>
      <b-field :label="extraQuestion.text_question">
        <b-input v-model="form.textAnswer" ref="textAnswer" required></b-input>
      </b-field>
      <b-field :label="extraQuestion.select_question">
        <b-radio v-for="(answerOption, index) in extraQuestion.answer_options"
                 :key="`extra_question_anwer_option_${index}`"
                 v-model="form.selectAnswer"
                 required
                 :name="answerOption"
                 :native-value="answerOption">
          {{ answerOption }}
        </b-radio>
      </b-field>
      <p v-if="showRadioError" class="help is-danger">Egy lehetőség választása kötelező</p>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="save">
        Mentés
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'SurveyExtraQuestionModal',
  components: {
    Modal,
  },
  data () {
    return {
      form: {
        textAnswer: null,
        selectAnswer: null,
      },
      isLoading: false,
      showRadioError: false
    }
  },
  methods: {
    async save () {
      this.showRadioError = !this.form.selectAnswer
      if (this.$refs.textAnswer.checkHtml5Validity() && this.form.selectAnswer) {
        this.isLoading = true
        await this.$store.dispatch('saveExtraQuestion', {
          text_question_id: this.extraQuestion.text_question_id,
          text_answer: this.form.textAnswer,
          select_question_id: this.extraQuestion.select_question_id,
          select_answer: this.form.selectAnswer,
        })
        this.isLoading = false
        this.closeModal()
      }
    },
    closeListener () {
      this.form.textAnswer = null
      this.form.selectAnswer = null
    },
    closeModal () {
      this.$store.dispatch('closeModal', this.$options.name)
    },
  },
  computed: {
    extraQuestion () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.extraQuestion
    },
  },
  watch: {
    extraQuestion: function (newVal, prevVal) {
      if (newVal) {
        this.form.textAnswer = newVal.text_answer
        this.form.selectAnswer = newVal.select_answer
      }
    },
  },
}
</script>
