import { downloadFile, MKVKRESTPromiseRequest, openFile } from '@/utils/utils'

export default {
  state: {
    name: null,
    extraData: null,
    questionBlocks: [],
  },
  mutations: {
    SET_SURVEY_DATA (state, survey) {
      state.name = survey.name
      state.extraData = survey.extra_data
      state.questionBlocks = survey.question_blocks
    },
    CLEAR_SURVEY_DATA (state, inspection) {
      state.name = null
      state.extraData = null
      state.questionBlocks = []
    },
    UPDATE_QUESTION (state, newQuestion) {
      for (const qbIndex in state.questionBlocks) {
        for (const qIndex in state.questionBlocks[qbIndex].questions) {
          if (Number(state.questionBlocks[qbIndex].questions[qIndex].id) === Number(newQuestion.id)) {
            state.questionBlocks[qbIndex].questions[qIndex] = newQuestion
            state.questionBlocks[qbIndex].questions = [...state.questionBlocks[qbIndex].questions]
            break
          }
        }
      }
    },
    UPDATE_EXTRA_QUESTION (state, extraQuestion) {
      for (const qbIndex in state.questionBlocks) {
        if (state.questionBlocks[qbIndex].extra_question) {
          if (Number(state.questionBlocks[qbIndex].id) === Number(extraQuestion.question_block_id)) {
            state.questionBlocks[qbIndex].extra_question = extraQuestion
          }
        }
      }
    },
    UPDATE_QUESTION_PROP (state, payload) {
      // questionblock question index alapján update?
      let found = false
      for (const questionBlock of state.questionBlocks) {
        for (const question of questionBlock.questions) {
          if (Number(question.id) === Number(payload.questionID)) {
            question[payload.key] = payload.newData
            found = true
            break
          }
        }
        if (found) break
      }
    },
  },
  actions: {
    async fetchSurvey ({ commit, rootState }, surveyID) {
      commit('CLEAR_SURVEY_DATA')
      const survey = await MKVKRESTPromiseRequest(rootState.api.GET_SURVEY, { survey_id: surveyID })
      if (survey) {
        commit('SET_SURVEY_DATA', survey)
      }
    },
    async setAnswer ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(rootState.api.SET_SURVEY_QUESTION_ANSWER,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
          answer: payload.answer,
        })
      if (response) {
        for (const question of response) {
          commit('UPDATE_QUESTION', question)
        }
      }
      return response
    },
    async modifyAnswer ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(rootState.api.MODIFY_SURVEY_QUESTION_ANSWER,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
          answer: payload.answer,
          comment: payload.committeeComment,
        })
      if (response) {
        for (const question of response) {
          commit('UPDATE_QUESTION', question)
        }
      }
      return response
    },
    async restoreAnswer ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(rootState.api.RESTORE_SURVEY_QUESTION_ANSWER,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
        })
      if (response) {
        for (const question of response) {
          commit('UPDATE_QUESTION', question)
        }
      }
      return response
    },
    async saveExtraQuestion ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(rootState.api.SET_SURVEY_EXTRA_QUESTION,
        {
          text_question_id: payload.text_question_id,
          text_answer: payload.text_answer,
          select_question_id: payload.select_question_id,
          select_answer: payload.select_answer,
        })
      if (response) {
        commit('UPDATE_EXTRA_QUESTION', response)
      }
      return response
    },
    async fillSurvey ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(rootState.api.SET_SURVEY_ALL_ANSWER,
        {
          survey_id: payload.surveyID,
        })
      if (response) {
        window.location.reload()
      }
      return response
    },
    async setComment ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(rootState.api.SET_SURVEY_QUESTION_COMMENT,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
          comment: payload.comment,
        })
      if (response) {
        commit('UPDATE_QUESTION', response)
      }
      return response
    },
    async setDocumentRegisterName ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.SET_SURVEY_QUESTION_DOCUMENT_REGISTER_NAME,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
          name: payload.documentRegisterName,
        })
      if (response) {
        commit('UPDATE_QUESTION', response)
      }
      return response
    },
    async deleteDocumentRegister ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DELETE_SURVEY_QUESTION_DOCUMENT_REGISTER,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
        })
      if (response) {
        commit('UPDATE_QUESTION', response)
      }
      return response
    },
    async uploadFileToDocumentRegister ({ commit, dispatch, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.UPLOAD_SURVEY_QUESTION_DOCUMENT_REGISTER_FILE,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
          filename: payload.filename,
          file: payload.file,
        })
      if (response && !!response.document_register && !!response.questions) {
        commit('UPDATE_QUESTION_PROP', {
          questionID: payload.questionID,
          newData: response.document_register,
          key: 'document_register',
        })
        dispatch('updateMultipleQuestion', response.questions)
      }
      return response
    },
    async attachFileToDocumentRegister ({ commit, rootState, dispatch }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.ATTACH_SURVEY_QUESTION_DOCUMENT_REGISTER_FILE,
        {
          survey_id: payload.surveyID,
          id: payload.questionID,
          external_document_id: payload.externalDocumentID,
        })
      if (response && !!response.document_register && !!response.questions) {
        dispatch('showMessage', {
          type: 'success',
          msg: 'Sikeres csatolás',
        })
        commit('UPDATE_QUESTION_PROP', {
          questionID: payload.questionID,
          newData: response.document_register,
          key: 'document_register',
        })
        dispatch('updateMultipleQuestion', response.questions)
      }
      return response
    },
    async downloadFileFromDocumentRegister ({ commit, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DOWNLOAD_SURVEY_QUESTION_DOCUMENT_REGISTER_FILE,
        {
          id: payload.documentID,
        })
      if (response) {
        if (payload?.isDownload) {
          downloadFile(response.content_type, response.file, response.filename)
        } else {
          openFile(response.content_type, response.file)
        }
      }
      return response
    },
    async deleteFileFromDocumentRegister ({ commit, dispatch, rootState }, payload) {
      const response = await MKVKRESTPromiseRequest(
        rootState.api.DELETE_FILE_FROM_SURVEY_QUESTION_DOCUMENT_REGISTER,
        {
          id: payload.documentID,
        })
      if (response && !!response.document_register && !!response.questions) {
        commit('UPDATE_QUESTION_PROP', {
          questionID: payload.questionID,
          newData: response.document_register,
          key: 'document_register',
        })
        dispatch('updateMultipleQuestion', response.questions)
      }
      return response
    },
    updateMultipleQuestion ({ commit }, questions) {
      for (const question of questions) {
        commit('UPDATE_QUESTION', question)
      }
    },
  },
  getters: {
    getSurveyStatistic: state => {
      const statisticData = {
        questionsNumber: 0,
        yesAnswersNumber: 0,
        noAnswersNumber: 0,
        notApplicableAnswersNumber: 0,
      }
      for (const questionBlock of state.questionBlocks) {
        for (const question of questionBlock.questions) {
          // TODO: szerver oldalról kéne egy megkülönböztető típus a kérdésre, információra, így késöbb lehet más block is
          if (question.answer_options.length > 0) {
            statisticData.questionsNumber++
            if (Number(question.answer) === 0) {
              statisticData.noAnswersNumber++
            } else if (Number(question.answer) === 1) {
              statisticData.yesAnswersNumber++
            } else if (Number(question.answer) === 2) {
              statisticData.notApplicableAnswersNumber++
            }
          }
        }
      }
      return statisticData
    },
    getDocumentRegister: state => questionID => {
      let documentRegister = null
      let found = false
      for (const questionBlock of state.questionBlocks) {
        for (const question of questionBlock.questions) {
          if (Number(question.id) === Number(questionID)) {
            found = true
            documentRegister = question.document_register
          }
        }
        if (found) break
      }
      return documentRegister
    },
  },
}
