<template>
  <Modal :name="$options.name"
         @close="clearForm"
         :is-loading="isLoading"
         title="Ellenőr, kérdőív hozzárendelése ellenőrzéshez">
    <template #body
              v-if="member && inspector && selectableSurveys">
      <div class="columns is-multiline is-gapless has-background-grey-lighter py-2 mb-2">
        <h5 class="title is-5 mb-1 column is-12">Ellenőrzés</h5>
        <div class="column is-4">Könyvvizsgáló</div>
        <div class="column is-8">{{ member.name }}</div>
        <div class="column is-4 has-background-grey-light">Területi szervezet</div>
        <div class="column is-8 has-background-grey-light">{{ member.region }}</div>
        <div class="column is-4">Cím</div>
        <div class="column is-8">{{ member.address }}</div>
      </div>
      <div v-if="client"
           class="columns is-multiline is-gapless has-background-grey-lighter py-2 mb-2">
        <h5 class="title is-5 mb-1 column is-12">Ügyfél</h5>
        <div class="column asd is-3 has-background-grey-light">Név</div>
        <div class="column is-3 has-background-grey-light has-text-weight-medium">{{ client.name }}</div>
        <div class="column is-3 has-background-grey-light">Fő tevékenység</div>
        <div class="column is-3 has-background-grey-light has-text-weight-medium">{{ client.main_activity }}</div>
        <div class="column is-3 ">Jelentés kibocsátása</div>
        <div class="column is-3  has-text-weight-medium">{{ client.report_interval }}</div>
        <div class="column is-3 ">Beszámoló fordulónapja</div>
        <div class="column is-3  has-text-weight-medium">{{ client.report_date }}</div>
        <div class="column is-3 has-background-grey-light">Kiadott vélemény fajtája</div>
        <div class="column is-3 has-background-grey-light has-text-weight-medium">{{ client.comment }}</div>
        <div class="column is-3 has-background-grey-light">Megbízás típusa</div>
        <div class="column is-3 has-background-grey-light has-text-weight-medium">{{ client.report_type }}</div>
        <div class="column is-3 ">Mérlegfőössszeg</div>
        <div class="column is-3  has-text-weight-medium">{{ formattedCurrency(client.summary) }}</div>
        <div class="column is-3 ">Árbevétel</div>
        <div class="column is-3  has-text-weight-medium">{{ formattedCurrency(client.income) }}</div>
        <div class="column is-3 has-background-grey-light">Összes munkaóra ráfordítás</div>
        <div class="column is-3 has-background-grey-light has-text-weight-medium">{{ client.work_hour }}</div>
        <div class="column is-3 has-background-grey-light">Megbízás díja ÁFA nélkül</div>
        <div class="column is-3 has-background-grey-light has-text-weight-medium">
          {{ formattedCurrency(client.commission_price) }}
        </div>
        <div class="column is-3 ">Közérdeklődésre számottartó gazdálkodó</div>
        <div class="column is-1">
          <b-icon pack="far"
                  :icon="client.public_interest ? 'check-square': 'square'"/>
        </div>
        <div class="column is-3">MNB Által felügyelt gazdálkodó</div>
        <div class="column is-1">
          <b-icon pack="far"
                  :icon="client.mnb ? 'check-square': 'square'"/>
        </div>
        <div class="column is-3">Értékpapír kibocsátó</div>
        <div class="column is-1">
          <b-icon pack="far"
                  :icon="client.stock ? 'check-square': 'square'"/>
        </div>
      </div>
      <div class="columns is-multiline is-gapless has-background-grey-lighter p-2 mb-2">
        <h5 class="title is-5 mb-1 column is-12">Ellenőr</h5>
        <div class="column is-4">{{ inspector.name }}</div>
        <div class="column is-4">{{ inspector.membership_number }}</div>
        <div class="column is-4">{{ inspector.organization }}</div>
      </div>
      <b-field
        label="Kérdőív választás">
        <b-select v-model="form.selectedSurveyID"
                  expanded>
          <option v-for="survey in selectableSurveys"
                  :key="`survey_select_${survey.id}`"
                  :value="survey.id">{{ survey.name }}
          </option>
        </b-select>
      </b-field>
      <div v-if="member.connected_inspections.length > 0">
        <b-field>
          <b-checkbox v-model="isConnectedMERAssignToo"
                      class="has-text-weight-bold">Kapcsolt cégek MER ellenőrzés kiválasztása
          </b-checkbox>
        </b-field>
        <div v-if="isConnectedMERAssignToo">
          <b-field v-for="(connectedInspection, index) in member.connected_inspections"
                   :key="`connectedInspectionMERToo_${index}`"
                   :label="connectedInspection.name">
            <b-select v-model="form.connectedMERSurveys[connectedInspection.inspection_id].questionnaire_id"
                      expanded>
              <option
                v-for="survey in connectedInspection.questionnaire_list"
                :value="survey.id"
                :key="`connectedMERSurveySelect_${connectedInspection.id}_${survey.id}`">
                {{ survey.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="save">
        OK
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import { formattedCurrency } from '@/utils/utils'

export default {
  name: 'InspectionAssignSurveySelectModal',
  components: {
    Modal,
  },
  data () {
    return {
      isLoading: false,
      isConnectedMERAssignToo: false,
      form: {
        selectedSurveyID: null,
        connectedMERSurveys: {},
      },
    }
  },
  methods: {
    formattedCurrency,
    async save () {
      this.isLoading = true
      const selectedSurvey = [
        {
          inspection_id: this.member.id,
          inspector_id: this.inspector.id,
          commission_id: this.client ? this.client.id : null,
          questionnaire_id: this.form.selectedSurveyID,
        },
      ]
      let selectedMERSurveys = []
      if (this.isConnectedMERAssignToo) {
        selectedMERSurveys = [...Object.values(this.form.connectedMERSurveys)]
        for (const survey of selectedMERSurveys) {
          survey.inspector_id = this.inspector.id
        }
      }
      const response = await this.$store.dispatch(
        'setInspectionAssign',
        [...selectedSurvey, ...selectedMERSurveys],
      )
      if (response) {
        // TODO: refresh row data...
        // noinspection ES6MissingAwait
        if (typeof this.successCallback === 'function') {
          this.successCallback(response.inspection_items, response.commission_items)
        }
        this.$store.dispatch('closeModal', this.$options.name)
        this.clearForm()
      }
      this.isLoading = false
    },
    clearForm () {
      this.form.selectedSurveyID = null
      this.form.connectedMERSurveys = {}
      this.isConnectedMERAssignToo = false
      this.isLoading = false
    },
  },
  computed: {
    member () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.member
    },
    client () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.client
    },
    inspector () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspector
    },
    selectableSurveys () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.selectableSurveys
    },
    successCallback () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.successCallback
    },
  },
  watch: {
    member: function (newMemberVal, prevVal) {
      if (newMemberVal && newMemberVal.connected_inspections) {
        if (newMemberVal.preselected_questionnaire) {
          this.form.selectedSurveyID = newMemberVal.preselected_questionnaire
        }
        for (const connectedInspection of newMemberVal.connected_inspections) {
          this.form.connectedMERSurveys[connectedInspection.inspection_id] = {
            inspection_id: connectedInspection.inspection_id,
            inspector_id: null,
            commission_id: null,
            questionnaire_id: connectedInspection.preselected_questionnaire,
          }
        }
      } else {
        this.form.connectedMERSurveys = {}
      }
    },
  },
}
</script>

<style scoped>
.columns.is-gapless > .column {
  padding: 0 1rem !important;
}

</style>
