import { format, isValid, parseISO } from 'date-fns'
import store from '@/store'
import { logOut } from '@/utils/auth'

export const arrayToString = (array) => {
  return Array.isArray(array) ? array.toString() : ''
}

export const formattedDate = (date) => {
  const fDate = parseISO(date)
  return isValid(fDate) ? format(fDate, 'yyyy. MM. dd.') : '-'
}

export const getMonthNameByNumber = (monthNumber) => {
  const date = new Date()
  date.setDate(1)
  date.setMonth(monthNumber - 1)

  return date.toLocaleString('hu-HU', { month: 'long' })
}

export const utf8ToB64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)))
}

export const b64ToUtf8 = (str) => {
  return decodeURIComponent(escape(window.atob(str)))
}

export const getInspectionStatusClass = (status) => {
  if (Number(status) === 1) return 'is-link'
  if (Number(status) === 2) return 'is-primary'
  if (Number(status) === 3) return 'is-warning'
  if (Number(status) === 4) return 'is-warning is-light'
  if (Number(status) === 5) return 'is-light'
  return 'is-light'
}

export const getInspectionSelectionStatusClass = (statusID) => {
  let statusClass = 'is-secondary'
  if (Number(statusID) === 0) statusClass = 'is-danger'
  if (Number(statusID) === 1) statusClass = 'is-warning'
  if (Number(statusID) === 2) statusClass = 'is-success'
  return statusClass
}

export const getInspectionBookingLimitColor = (limit, currentReservationCount) => {
  let color = 'success'
  const warningLimit = 0.25
  const dangerLimit = 0.1
  if (Number(limit) * warningLimit < Number(currentReservationCount)) color = 'warning-dark'
  if (Number(limit) * dangerLimit < Number(currentReservationCount)) color = 'danger'
  return color
}

export const pingServer = (url) => {
  return new Promise((resolve, reject) => {
    const startTime = new Date().getTime()
    const response = {
      status: 'error',
      time: null,
    }
    const img = new Image()
    img.onload = () => {
      response.status = 'success'
      response.time = new Date().getTime() - startTime
      resolve(response)
    }
    img.onerror = () => {
      reject(response)
    }
    img.src = url + '?t=' + startTime
  })
}

export const RESTPromiseRequest = (url, method = 'GET', params = null, body = null,
  headers = false) => {
  return new Promise((resolve, reject) => {
    let urlWithQuery = url
    const fetchOptions = {
      method: method,
    }

    if (headers) fetchOptions.headers = headers

    if (body) fetchOptions.body = JSON.stringify(body)

    if (params && typeof params === 'object') urlWithQuery += '?' + new URLSearchParams(params)

    fetch(urlWithQuery, fetchOptions)
      .then(response => {
        if (!response.ok) {
          // log out user
          if (Number(response.status) === 401) {
            logOut()
          }

          throw new Error('HTTP status ' + response.status)
        }
        return response.json()
      })
      .then(data => {
        // console.log('Success:', data);
        console.log('RESTPromiseRequest', url, data)
        resolve(data)
      })
      .catch((error) => {
        console.error('RESTPromiseRequest | Error:', url, error)
        reject(error)
      })
  })
}

export const MKVKRESTPromiseRequest = async (url, body = {}, localCall = false) => {
  const fullUrl =
    localCall
      ? store.state.api.LOCAL_URL + url
      : process.env.NODE_ENV === 'production'
        ? (store.state.api.BASE_URL + url)
        : (store.state.api.BASE_URL_DEV + url)
  const fullBody = {
    ...body,
    ...{
      jwt: window.localStorage.getItem('jwt'),
      api_key: store.state.api_key,
      client_version: process.env.VUE_APP_VERSION,
    },
  }

  if (store.state.adminAliasUser) fullBody.user_id = store.state.adminAliasUser.ID

  try {
    const response = await RESTPromiseRequest(fullUrl, 'POST', {}, fullBody)
    if (Number(response.status) === 0 && response.error_message) {
      if ([5000, 5001, 5002].includes(Number(response.error_code))) {
        logOut()
      }
      // TODO: Peti egyeztet error code
      if (Number(response.error_code === 1111)) {
        // TODO: redirect to permission denied
      }
      await store.dispatch('showMessage', {
        type: 'warning',
        actionText: 'Hibakód másolása',
        indefinite: true,
        cancelText: 'OK',
        onAction: () => {
          navigator.clipboard.writeText(response.reference).then(async function () {
            await store.dispatch('showMessage', {
              type: 'success',
              msg: 'Hibakód sikeresen másolva a vágólapra',
            })
          }, async function () {
            await store.dispatch('showMessage', {
              type: 'warning',
              actionText: 'OK',
              onAction: () => {
              },
              msg: `Sikertelen másolás, hivatkozzon az alábbi hibakódra, ref: ${response.reference}`,
            })
          })
        },
        msg: `${response.error_message} | ref: ${response.reference}`,
      })
    } else {
      return response.data
    }
  } catch (e) {
    await store.dispatch('showMessage', { type: 'danger' })
    return false
  }
}

export const addEventToGoogleCalendar = (title, date, address) => {
  // TODO: date nem megy
  const googleUrl = encodeURI(`https://www.google.com/calendar/render?action=TEMPLATE&text=
  ${title}&dates=${date}&details=${title}&location=${address}&sprop=&sprop=name:`)

  console.log('### googleCalendarLink', googleUrl)
  window.open(googleUrl, '_blank')
}

export const eventICalendarDownload = (title, date, address) => {
  const ical = require('ical-generator')
  const calendar = ical({ name: 'MBVK Minel' })
  const startTime = new Date(date)
  const endTime = new Date(date)
  calendar.createEvent({
    start: startTime,
    end: endTime,
    summary: title,
    description: title,
    location: address,
    url: '',
  })

  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'

  const blob = calendar.toBlob()
  const url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = `${title} naptár esemény`
  a.click()
  window.URL.revokeObjectURL(url)
  a.parentNode.removeChild(a)
}

export const getFileIconClass = (extension) => {
  let fileIconClass = 'file'
  const wordExtensionList = ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb']
  const excelExtensionList = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsm', 'xla', 'xlam', 'xll', 'xlw']
  const imageExtensionList = ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'psd', 'eps', 'ai', 'indd', 'raw']

  if (extension === 'pdf') {
    fileIconClass = 'file-pdf'
  } else if (wordExtensionList.includes(extension)) {
    fileIconClass = 'file-word'
  } else if (excelExtensionList.includes(extension)) {
    fileIconClass = 'file-excel'
  } else if (imageExtensionList.includes(extension)) {
    fileIconClass = 'file-image'
  }
  return fileIconClass
}

export const formattedCurrency = txt => {
  return Number(txt) ? Number(txt).toLocaleString('hu') + 'E Ft' : txt
}

export const getBase64URL = (contentType, file) => {
  return `data:${contentType};base64,${file}`
}

export const openFile = (contentType, file) => {
  const base64URL = getBase64URL(contentType, file)
  const win = window.open()
  win.document.write(`
                <iframe src="${base64URL}" frameborder="0" style="border:0; top:0;
                left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>
                `)
}

export const downloadFile = (contentType, file, filename) => {
  const base64URL = getBase64URL(contentType, file)
  const downloadLink = document.createElement('a')
  downloadLink.href = base64URL
  downloadLink.download = filename
  downloadLink.click()
  downloadLink.remove()
}

export const getAnswerText = answerID => {
  let text = answerID
  if (Number(answerID) === 1) text = 'Igen'
  if (Number(answerID) === 0) text = 'Nem'
  if (Number(answerID) === 2) text = 'N/É'
  return text
}
