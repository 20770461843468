<template>
  <Modal :name="$options.name"
         title="Új hír">
    <template #body>
      <b-field label="Cím">
        <b-input v-model="newsTitle"></b-input>
      </b-field>
      <b-field label="Hír">
        <b-input v-model="newsText"
                 maxlength="500"
                 type="textarea"></b-input>
      </b-field>
    </template>
    <template #footer>
      <b-button type="is-primary"
                @click="save">Testbtn
      </b-button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'

export default {
  name: 'CreateNewsModal',
  components: {
    Modal,
  },
  data () {
    return {
      newsTitle: null,
      newsText: null,
    }
  },
  methods: {
    save () {
      alert(`save ${this.$options.name}`)
    },
  },
}
</script>

<style scoped>

</style>
