<template>
  <b-navbar class="gradient-navbar">
    <!-- TODO: menüpontok hozzáférhetősége -->
    <!--suppress HtmlUnknownAttribute -->
    <template #brand>
      <router-link class="is-disabled"
                   to="/"
                   custom
                   v-slot="{ href, route, navigate, isActive }">
        <b-navbar-item :active="isActive"
                       :href="href"
                       @click="navigate"
                       class="has-text-white">
          <!--suppress HtmlUnknownTarget -->
          <img src="/img/logo_v3.png"
               alt="Minell">
          <span class="ml-1 has-text-weight-bold">Minell</span>
          <span class="has-text-grey is-size-7 ml-1">v{{ VERSION }}</span>
          <b-field v-if="NODE_ENV !== 'production'"
                   class="ml-2">
            <b-tag type="is-danger">{{ NODE_ENV }}</b-tag>
          </b-field>
        </b-navbar-item>
      </router-link>
    </template>
    <!--suppress HtmlUnknownAttribute -->
    <template #start>
      <router-link class="is-disabled"
                   to="/News"
                   custom
                   v-slot="{ href, route, navigate, isActive }">
        <b-navbar-item :active="isActive"
                       :href="href"
                       @click="navigate">
          Hírek
        </b-navbar-item>
      </router-link>
      <router-link v-if="$store.getters.minUserLevelInspector"
                   class="is-disabled"
                   to="/Calendar"
                   custom
                   v-slot="{ href, route, navigate, isActive }">
        <b-navbar-item :active="isActive"
                       :href="href"
                       @click="navigate">
          Naptár
        </b-navbar-item>
      </router-link>
      <router-link v-if="$store.getters.minUserLevelLawyer"
                   class="is-disabled"
                   to="/Inspections"
                   custom
                   v-slot="{ href, route, navigate, isActive }">
        <b-navbar-item :active="isActive"
                       :href="href"
                       @click="navigate">
          Ellenőrzések
        </b-navbar-item>
      </router-link>
      <router-link v-if="$store.getters.minUserLevelGroupLeader"
                   class="is-disabled"
                   to="/inspection-assign"
                   custom
                   v-slot="{ href, route, navigate, isActive }">
        <b-navbar-item :active="isActive"
                       :href="href"
                       @click="navigate">
          Ellenőrzés kiválasztása
        </b-navbar-item>
      </router-link>
      <router-link v-if="$store.getters.minUserLevelGroupLeader"
                   class="is-disabled"
                   to="/inspection-plan"
                   custom
                   v-slot="{ href, route, navigate, isActive }">
        <b-navbar-item :active="isActive"
                       :href="href"
                       @click="navigate">
          Ellenőrzési terv
        </b-navbar-item>
      </router-link>
      <b-navbar-dropdown v-if="$store.getters.minUserLevelAdmin"
                         label="Admin">
        <router-link to="/"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            Visszaküldés az ellenőrnek
          </b-navbar-item>
        </router-link>
        <b-navbar-item
          @click="$store.dispatch('openModal', 'CreateNewsModal')">
          Új hír
        </b-navbar-item>
        <router-link to="/"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            Kérdőív szerkesztő
          </b-navbar-item>
        </router-link>
      </b-navbar-dropdown>
      <b-navbar-dropdown v-if="NODE_ENV === 'development' && $store.getters.minUserLevelAdmin"
                         label="For Test">
        <router-link to="/Inspection/10/details"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            Ellenőrzés
          </b-navbar-item>
        </router-link>
        <router-link to="/NotFound"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            404 Not Found
          </b-navbar-item>
        </router-link>
        <router-link to="/ModalTesterPage"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            ModalTesterPage
          </b-navbar-item>
        </router-link>
        <router-link to="/test1"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            Test page 1
          </b-navbar-item>
        </router-link>
        <router-link to="/test2"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            Test page 2
          </b-navbar-item>
        </router-link>
        <router-link to="/test3"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="navigate">
            Test page 3
          </b-navbar-item>
        </router-link>
      </b-navbar-dropdown>
    </template>
    <!-- TODO: Dinamic name -->
    <!--suppress HtmlUnknownAttribute -->
    <template #end>
      <b-navbar-dropdown :label="$store.getters.userName">
        <router-link v-if="$store.getters.minUserLevelGroupLeader"
                     to="/"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item :active="isActive"
                         @click="$store.dispatch('openModal', 'InspectorSelectModal')">
            Ellenőr választás
          </b-navbar-item>
        </router-link>
        <router-link to="/"
                     custom
                     v-slot="{ href, route, navigate, isActive }">
          <b-navbar-item
            :active="isActive"
            :href="href"
            @click="logOut">
            Kijelentkezés
          </b-navbar-item>
        </router-link>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { logOut } from '@/utils/auth'

export default {
  data: () => ({
    NODE_ENV: process.env.NODE_ENV,
    VERSION: process.env.VUE_APP_VERSION,
    tmpNavActive: false, // TODO: nav active logic...
  }),
  methods: {
    logOut,
  },
}
</script>
