<template>
  <ul>
    <li v-for="(file, fileIndex) in files"
        :key="`userAttachedDocument_${file.id}`"
        class="p-1">
      <!--      <b-icon :icon="getFileIconClass(file.ext)"/>-->
      <FileIcon :ext="file.ext || ''"/>
      <b-tooltip :label="file.name || file.filename"
                 position="is-top">
        <span class="file-name-container">{{ file.name || file.filename }}</span>
      </b-tooltip>
      <b-button type="is-ghost"
                icon-left="eye"
                @click="$emit('openFile', file.id)"
                class="text-button-height-modifier is-pulled-right">
      </b-button>
      <b-button type="is-ghost"
                icon-left="file-download"
                @click="$emit('downloadFile', file.id, 'download')"
                class="text-button-height-modifier is-pulled-right">
      </b-button>
      <b-button v-if="hasFileDelete"
                @click="$emit('deleteFile', file.id, fileIndex)"
                icon-left="trash"
                class="text-button-height-modifier has-text-danger is-pulled-right"
                type="is-ghost">
      </b-button>
    </li>
  </ul>
</template>

<script>
import { getFileIconClass } from '@/utils/utils'
import FileIcon from '@/components/FileIcon'

export default {
  name: 'FileList',
  components: { FileIcon },
  props: {
    files: {
      type: Array,
      required: true,
    },
    hasFileDelete: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFileIconClass,
  },
}
</script>

<style lang="scss"
       scoped>
@import "bulma/sass/utilities/initial-variables";

ul {
  li:nth-child(2n) {
    background: $grey-lighter;
  }
}

.file-name-container {
  display: inline-block;
  width: 450px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

</style>
