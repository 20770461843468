import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn, logIn } from '@/utils/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import('@/views/Home'),
    component: () => import('@/views/News'),
    meta: { hasContainer: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/News'),
    meta: { hasContainer: true },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('@/views/Calendar'),
    meta: {
      hasContainer: true,
      minUserLevel: 'INSPECTOR',
    },
  },
  {
    path: '/inspections',
    name: 'Inspections',
    component: () => import('@/views/Inspections'),
    meta: {
      hasContainer: true,
      minUserLevel: 'LAWYER',
    },
  },
  {
    path: '/inspection/:inspectionID',
    name: 'Inspection',
    component: () => import('@/views/Inspection/Main'),
    children: [
      {
        path: 'details',
        name: 'InspectionDetails',
        component: () => import('@/views/Inspection/Details'),
        meta: {
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'statement',
        name: 'InspectionStatements',
        component: () => import('@/views/Inspection/Lists'),
        meta: {
          title: 'Nyilatkozatok',
          key: 'statements',
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'statement/:statementID',
        name: 'Statement',
        component: () => import('@/views/Inspection/Statement'),
        meta: {
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'survey',
        name: 'InspectionSurveys',
        component: () => import('@/views/Inspection/Lists'),
        meta: {
          title: 'Kérdőívek',
          key: 'surveys',
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'survey/:surveyID',
        name: 'Survey',
        component: () => import('@/views/Inspection/Survey'),
        meta: {
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'report',
        name: 'InspectionReports',
        component: () => import('@/views/Inspection/Lists'),
        meta: {
          title: 'Jelentések',
          key: 'reports',
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'report/:reportID',
        name: 'Report',
        component: () => import('@/views/Inspection/Report'),
        meta: {
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'final-report',
        name: 'InspectionFinalReports',
        component: () => import('@/views/Inspection/Lists'),
        meta: {
          title: 'Zárójelentések',
          key: 'final_reports',
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'final-report/:reportID',
        name: 'FinalReport',
        component: () => import('@/views/Inspection/FinalReport'),
        meta: {
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'document-register',
        name: 'DocumentRegister',
        component: () => import('@/views/Inspection/DocumentRegisterSummary'),
        meta: {
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: 'summary',
        name: 'InspectionSummery',
        component: () => import('@/views/Inspection/Summary'),
        meta: {
          minUserLevel: 'LAWYER',
        },
      },
      {
        path: '*',
        component: () => import('@/views/NotFound'),
        // redirect: 'details'
      },
    ],
    meta: {
      minUserLevel: 'LAWYER',
    },
  },
  {
    path: '/inspection-assign',
    name: 'InspectionAssign',
    component: () => import('@/views/InspectionAssign'),
    meta: {
      hasContainer: true,
      minUserLevel: 'GROUP_LEADER',
    },
  },
  {
    path: '/inspection-plan',
    name: 'InspectionPlan',
    component: () => import('@/views/InspectionPlan'),
    meta: {
      hasContainer: false,
      minUserLevel: 'GROUP_LEADER',
    },
  },
  {
    path: '/modaltesterpage',
    name: 'ModalTesterPage',
    component: () => import('@/views/ModalTesterPage'),
  },
  {
    path: '/test1',
    name: 'Test1',
    component: () => import('@/views/TestPages/Test1'),
    meta: { hasContainer: true },
  },
  {
    path: '/test2',
    name: 'Test2',
    component: () => import('@/views/TestPages/Test2'),
    meta: { hasContainer: true },
  },
  {
    path: '/test3',
    name: 'Test3',
    component: () => import('@/views/TestPages/Test3'),
    meta: { hasContainer: true },
  },
  {
    path: '/no-permission',
    name: 'NoPermission',
    component: () => import('@/views/NoPermission'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound'),
    // redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.name !== 'Login')
  //
  // if (requiresAuth && !isLoggedIn()) {
  //   next('Login')
  // } else {
  //   next()
  // }

  if (to.matched.some(record => record.name === 'Login')) {
    if (to.query && to.query.jwt) {
      if (window.localStorage.getItem('jwt') !== to.query.jwt) {
        logIn(to.query.jwt)
      }
    }
  }

  if (requiresAuth && !isLoggedIn()) {
    next('Login')
  } else if (!requiresAuth && isLoggedIn()) {
    next('/')
  } else {
    if (to.meta.minUserLevel === 'ADMIN' && store.getters.minUserLevelAdmin) {
      next()
    } else if (to.meta.minUserLevel === 'GROUP_LEADER' && store.getters.minUserLevelGroupLeader) {
      next()
    } else if (to.meta.minUserLevel === 'INSPECTOR' && store.getters.minUserLevelInspector) {
      next()
    } else if (to.meta.minUserLevel === 'LAWYER' && store.getters.minUserLevelLawyer) {
      next()
    } else if (!to.meta.minUserLevel) {
      // EVERYBODY CAN ACCESS
      next()
    } else {
      // HASN'T PERMISSION TO ACCESS
      next('NoPermission')
    }
  }
})

export default router
