<template>
  <div>
    <TestModal/>
    <CreateNewsModal/>
    <ChangePasswordModal/>
    <SurveyCommentModal/>
    <InspectionBookDateModal/>
    <GoogleMapsModal/>
    <InspectorSelectModal/>
    <DocumentRegisterModal/>
    <SurveyExtraQuestionModal/>
    <AttachedDocumentsTreeModal/>
    <InspectionCommissionDetailsModal/>
    <ConfirmationModal/>
    <InspectionAssignSurveySelectModal/>
    <InspectionErrorsModal/>
    <InspectionPrintModal/>
    <InspectionFinalDocumentsAttachModal/>
    <SurveyModifyQuestionOriginalAnswer/>
    <InspectionPlanCommentModal/>
    <InspectionPlanAddMemberModal/>
  </div>
</template>

<script>
import TestModal from '@/components/Modals/TestModal'
import CreateNewsModal from '@/components/Modals/CreateNewsModal'
import ChangePasswordModal from '@/components/Modals/ChangePasswordModal'
import SurveyCommentModal from '@/components/Modals/SurveyCommentModal'
import InspectionBookDateModal from '@/components/Modals/InspectionBookDateModal'
import GoogleMapsModal from '@/components/Modals/GoogleMapsModal'
import DocumentRegisterModal from '@/components/Modals/DocumentRegisterModal'
import SurveyExtraQuestionModal from '@/components/Modals/SurveyExtraQuestionModal'
import AttachedDocumentsTreeModal from '@/components/Modals/AttachedDocumentsTreeModal'
import InspectionCommissionDetailsModal from '@/components/Modals/InspectionCommissionDetailsModal'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import InspectorSelectModal from '@/components/Modals/InspectorSelectModal'
import InspectionAssignSurveySelectModal from '@/components/Modals/InspectionAssignSurveySelectModal'
import InspectionErrorsModal from '@/components/Modals/InspectionErrorsModal'
import InspectionPrintModal from '@/components/Modals/InspectionPrintModal'
import InspectionFinalDocumentsAttachModal from '@/components/Modals/InspectionFinalDocumentsAttachModal'
import SurveyModifyQuestionOriginalAnswer from '@/components/Modals/SurveyModifyQuestionOriginalAnswer'
import InspectionPlanCommentModal from './InspectionPlanCommentModal.vue'
import InspectionPlanAddMemberModal from './InspectionPlanAddMemberModal.vue'

export default {
  components: {
    InspectionPlanAddMemberModal,
    InspectionPlanCommentModal,
    SurveyModifyQuestionOriginalAnswer,
    InspectionFinalDocumentsAttachModal,
    InspectionPrintModal,
    InspectionErrorsModal,
    InspectionAssignSurveySelectModal,
    InspectorSelectModal,
    ConfirmationModal,
    InspectionCommissionDetailsModal,
    AttachedDocumentsTreeModal,
    SurveyExtraQuestionModal,
    DocumentRegisterModal,
    GoogleMapsModal,
    InspectionBookDateModal,
    SurveyCommentModal,
    TestModal,
    CreateNewsModal,
    ChangePasswordModal,
  },
  created () {
    // Modal open scrollbar fix
    const style = document.createElement('style')
    const scrollbarWidth = window.innerWidth - document.scrollingElement.clientWidth
    style.textContent = `.is-clipped {margin-right: ${scrollbarWidth}px;}`
    document.head.appendChild(style)
  },
}
</script>
