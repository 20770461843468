export default {
  state: {
    modalRegistry: {},
    isTestModalActive: false,
    modalPayloadData: {},
  },
  mutations: {
    CLOSE_MODAL (state, modalName) {
      state.modalRegistry[modalName] = false
    },
    OPEN_MODAL (state, modalName) {
      state.modalRegistry[modalName] = true
    },
    REGISTER_MODAL (state, modalName) {
      const newModal = {}
      const newModalPayload = {}
      newModal[modalName] = false
      newModalPayload[modalName] = null
      state.modalRegistry = { ...state.modalRegistry, ...newModal }
      state.modalPayloadData = { ...state.modalPayloadData, ...newModalPayload }
    },
    ADD_MODAL_PAYLOAD_DATA (state, payload) {
      state.modalPayloadData[payload.modalName] = payload.data
    },
    CLEAR_MODAL_PAYLOAD_DATA (state, modalName) {
      state.modalPayloadData[modalName] = null
    },
  },
  actions: {
    closeModal ({ state, commit }, modalName) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.modalRegistry.hasOwnProperty(modalName)) {
        commit('CLOSE_MODAL', modalName)
        // eslint-disable-next-line no-prototype-builtins
        if (state.modalPayloadData.hasOwnProperty(modalName)) {
          commit('CLEAR_MODAL_PAYLOAD_DATA', modalName)
        }
      } else {
        // TODO: some error handling
        alert(`closeModal | Cant find final ${modalName}`)
      }
    },
    openModal ({ state, commit }, payload) {
      let modalName = payload
      if (typeof payload === 'object') {
        modalName = payload.modalName
        commit('ADD_MODAL_PAYLOAD_DATA', { modalName: payload.modalName, data: payload.data })
      }
      // eslint-disable-next-line no-prototype-builtins
      if (state.modalRegistry.hasOwnProperty(modalName)) {
        commit('OPEN_MODAL', modalName)
      } else {
        // TODO: some error handling
        alert(`openModal | Cant find final ${modalName}`)
      }
    },
    registerModal ({ state, commit }, modalName) {
      // console.log('modalName', modalName)
      // eslint-disable-next-line no-prototype-builtins
      if (!state.modalRegistry.hasOwnProperty(modalName)) {
        commit('REGISTER_MODAL', modalName)
      } else {
        // TODO: some error handling
        console.log(`registerModal | Modal already registered ${modalName}`)
      }
    },
  },
  getters: {},
}
