<template>
  <Modal :name="$options.name"
         @close="clearData"
         title="Ellenőrzés részletei">
    <template #body>
      <div v-if="isLoaded">
        <div v-for="(detail, index) in details"
             :key="`commissionDetails_${index}_${inspectionID}`"
             class="columns is-multiline">
          <div class="column is-6">Ellenőrzött</div>
          <div class="column is-6">{{ detail.name }}</div>
          <div class="column is-6">Adószám</div>
          <div class="column is-6">{{ detail.tax_number }}</div>
          <div class="column is-6">Ellenőrzés típusa</div>
          <div class="column is-6">{{ detail.report_type }}</div>
          <div class="column is-6">Ellenőrzés induka</div>
          <div class="column is-6">{{ detail.audit_opinion }}</div>
          <div class="column is-6">Kérdőív</div>
          <div class="column is-6">{{ detail.survey_name }}</div>
          <div class="column is-6">Vállalkozási forma</div>
          <div class="column is-6">{{ detail.company }}</div>
          <div class="column is-6">Folytatott tevékenység</div>
          <div class="column is-6">{{ detail.activity_type }}</div>
          <div class="column is-6">Bevétel</div>
          <div class="column is-6">{{ formattedCurrency(detail.income) }}</div>
          <div class="column is-6">Egyenleg</div>
          <div class="column is-6">{{ formattedCurrency(detail.balance) }}</div>
          <div v-if="index + 1 < detail.length" class="divider"></div>
        </div>
      </div>
      <div v-else>
        <Loading :is-active="true"/>
      </div>
    </template>
    <template #footer>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modals/common/BasicModal'
import Loading from '@/components/Loading'
import { formattedCurrency } from '@/utils/utils'

export default {
  name: 'InspectionCommissionDetailsModal',
  components: {
    Modal,
    Loading,
  },
  data () {
    return {
      details: [],
      isLoaded: false,
    }
  },
  methods: {
    formattedCurrency,
    clearData () {
      this.details = []
    },
  },
  computed: {
    inspectionID () {
      return this.$store.state.modal.modalPayloadData[this.$options.name]?.inspectionID
    },
  },
  watch: {
    inspectionID: async function (newVal, prevVal) {
      this.isLoaded = false
      this.clearData()
      if (newVal) {
        const resp = await this.$store.dispatch('fetchInspectionCommissionDetails', newVal)
        if (resp) {
          this.details = resp
        }
      }
      this.isLoaded = true
    },
  },
}
</script>

<style scoped>

</style>
